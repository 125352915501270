import React from 'react'

const Support = () => (
  <svg width="26" height="26" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h26v26H0z" />
      <path
        d="M8.366 15.726l.173-.21.407-.497.667.077c.4.047.808.07 1.22.07 4.239 0 7.584-2.484 7.584-5.416 0-2.932-3.345-5.417-7.584-5.417-4.238 0-7.583 2.485-7.583 5.417 0 1.268.62 2.48 1.756 3.456l.602.517-1.618 3.552c1.73.477 3.116-.024 4.376-1.549zm-6.524 3.028l-.262-.13a.542.542 0 0 1-.254-.707l1.645-3.672c-1.21-1.281-1.888-2.843-1.888-4.495 0-4.248 4.415-7.583 9.75-7.583 5.336 0 9.75 3.335 9.75 7.583s-4.414 7.583-9.75 7.583c-.276 0-.552-.009-.825-.026-1.754 2.09-4.857 2.695-8.166 1.447zm18.067.941l.654-.52c.675-.538 1.335-1.871 1.335-2.66 0-.667-.21-1.323-.556-1.726a1.083 1.083 0 1 1 1.642-1.414c.712.826 1.08 1.985 1.08 3.14 0 1.193-.648 2.72-1.534 3.752l.838 1.896c.12.27.001.584-.266.71l-.48.224c-1.94.84-3.786.448-5.062-.722a8.284 8.284 0 0 1-.227.003c-2.127 0-4.098-.822-5.312-2.205a1.083 1.083 0 1 1 1.628-1.43c.786.896 2.159 1.468 3.684 1.468.192 0 .383-.009.57-.027l.567-.053.366.435c.557.664 1.139.952 1.846.878l-.773-1.749zm-9.076-8.862a1.083 1.083 0 1 0 0-2.166 1.083 1.083 0 0 0 0 2.166zm3.25 0a1.083 1.083 0 1 0 0-2.166 1.083 1.083 0 0 0 0 2.166zm-6.5 0a1.083 1.083 0 1 0 0-2.166 1.083 1.083 0 0 0 0 2.166z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default Support

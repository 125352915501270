export const DEFAULT_LOCALE = 'en'
export const SET_WEBVIEW = 'app/SET_WEBVIEW'

export const LOGOUT = 'app/LOGOUT'

export const RESET = 'app/RESET'

export const SET_HAS_NO_MEMBER_OR_METER = 'app/SET_HAS_NO_MEMBER_OR_METER'
export const SWITCH_METER_REQUEST = 'app/SWITCH_METER_REQUEST'
export const SWITCH_METER_SUCCESS = 'app/SWITCH_METER_SUCCESS'
export const CLOSE_SWITCH_MODAL = 'app/CLOSE_SWITCH_MODAL'
export const IN_APP_MESSAGES_SUCCESS = 'app/IN_APP_MESSAGES_SUCCESS'
export const SHOW_MESSAGES_INBOX = 'app/SHOW_MESSAGES_INBOX'

export const EXPERIMENT_DECIDED = `app/EXPERIMENT_DECIDED`

export const RENDERING_DASHBOARD = 'app/RENDERING_DASHBOARD'

import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import styled from 'styled-components'
import mq from 'styles/mq'
import { input, color } from 'styles/variables'
import RadioButton from 'components/buttons/RadioButton'
import { Text } from 'components/text'
import { Trans } from '@lingui/macro'

import Apartment from './assets/Apartment'
import LargeHome from './assets/LargeHome'
import SmallHome from './assets/SmallHome'

export const Label = styled.label`
  flex: 0 0 157px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${input.borderColor};
  border-color: ${({ isChecked }) =>
    isChecked ? input.borderColorFocused : input.borderColor};
  background: ${({ isChecked }) => (isChecked ? '#f2f3f6' : 'white')};
  transition: 0.2s background, 0.2s border-color;
  padding: 12px 10px;
  margin-left: 10px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;

  &:first-child {
    margin: 0;
  }

  ${mq.tablet`
    &:hover {
      background: #f2f3f6;
    }
  `};
`

export const HiddenInput = styled.input`
  position: absolute;
  left: -9999px;
  visibility: hidden;
`

export const Radio = styled.div`
  position: relative;
  width: 25px;
  height: 25px;
  flex: 0 0 25px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid ${input.borderColorFocused};
  margin-right: 10px;

  &::after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: ${props => (props.isChecked ? color.primary : '#fff')};
  }
`

const getIcon = value => {
  switch (value) {
    case 'small':
      return Apartment
    case 'medium':
      return SmallHome
    case 'large':
      return LargeHome
    default:
      return () => null
  }
}

const getLabel = value => {
  switch (value) {
    case 'small':
      return <Trans>Apartment/Condo</Trans>
    case 'medium':
      return <Trans>Small Home</Trans>
    case 'large':
      return <Trans>Large Home</Trans>
    default:
      return null
  }
}

const getDesc = value => {
  switch (value) {
    case 'small':
      return <Trans>~750 kWh/mo</Trans>
    case 'medium':
      return <Trans>~1,500 kWh/mo</Trans>
    case 'large':
      return <Trans>~2,500 kWh/mo</Trans>
    default:
      return null
  }
}

const HomeSizeRadio = ({ value, onClick }) => (
  <Field
    type="radio"
    name="home_size"
    value={value}
    render={({ field, form: { values } }) => {
      const isChecked = values.home_size === value

      const Icon = getIcon(value)
      const label = getLabel(value)
      const desc = getDesc(value)

      return (
        <Label
          isChecked={isChecked}
          data-testid={`homesize:${value}`}
          onClick={onClick}
        >
          <HiddenInput type="radio" checked={isChecked} {...field} />
          <Icon isChecked={isChecked} />
          <Text small medium mt="8px">
            {label}
          </Text>
          <Text fontSize="1.2rem" color="#7D8D9E" mb="10px" lineHeight="1rem">
            {desc}
          </Text>
          <RadioButton isChecked={isChecked} m="0" />
        </Label>
      )
    }}
  />
)

HomeSizeRadio.propTypes = {
  value: PropTypes.oneOf(['small', 'medium', 'large']),
  onClick: PropTypes.func,
}

export default HomeSizeRadio

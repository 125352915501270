import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import yup from 'yup'
import { Box } from 'grid-styled'
import { Trans } from '@lingui/macro'

import * as mixpanel from 'utils/mixpanel'

import Form from 'components/Form'
import { Button, LinkButton } from 'components/buttons'
import Field from 'components/Form/Field'

import ModalBody from '../ModalBody'

const schema = yup.object().shape({
  zip_code: yup
    .string()
    .required(<Trans>Please enter a valid zip code</Trans>)
    .matches(/[0-9]/, <Trans>Zip codes can only contain numbers</Trans>)
    .matches(/^[0-9]{5}$/, <Trans>Zip codes must be 5 digits long</Trans>),
})

/* eslint-disable no-nested-ternary */
const ZipCodeForm = ({
  formStatus,
  zipCode,
  isChanging,
  zipCodeNotInArea,
  onSetZipCodeNotInArea,
  onSubmit,
  onContinue,
  onClose,
}) => {
  const title = zipCodeNotInArea ? (
    <Trans>We're Not in Your Area Yet</Trans>
  ) : isChanging ? (
    <Trans>Change Your Zip Code</Trans>
  ) : (
    <Trans>Welcome to Griddy</Trans>
  )

  const subTitle = zipCodeNotInArea ? (
    <Trans>
      You’re still welcome to explore the app as our guest. All data is based
      off Houston market information so you can get a feel for Griddy before we
      make it to your neighborhood.
    </Trans>
  ) : isChanging ? (
    <Box width={[1, '400px']}>
      <Trans>
        Enter in a new zip code to see the wholesale price of electricity in
        your neighborhood.
      </Trans>
    </Box>
  ) : (
    <Box width={[1, '400px']}>
      <Trans>
        Enter your zip code to see the wholesale price of electricity in your
        neighborhood.
      </Trans>
    </Box>
  )

  return (
    <ModalBody
      dataTestId="zipCodeModal"
      formStatus={formStatus}
      title={title}
      subTitle={subTitle}
      alignLeft={isChanging}
      onClose={onClose}
    >
      <Formik
        initialValues={{ zip_code: zipCode }}
        validationSchema={zipCodeNotInArea ? null : schema}
        onSubmit={onSubmit}
        render={({ isSubmitting, isValid, status = {} }) => (
          <Form
            isSubmitting={isSubmitting}
            isValid={isValid}
            width={['280px', '250px']}
            mt={['20px', '30px']}
          >
            {!zipCodeNotInArea ? (
              <>
                <Field
                  label={'Zip Code'}
                  name="zip_code"
                  type="tel"
                  mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                  guide={false}
                  autoComplete="postal-code"
                  errorPositionAbsolute
                  mb={30}
                />
                <Button
                  data-testid="submitButton"
                  loading={isSubmitting}
                  width={['140px']}
                >
                  {isChanging ? <Trans>Save</Trans> : <Trans>Enter</Trans>}
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="button"
                  onClick={() => {
                    mixpanel.outOfAreaModal({ type: 'continue' })
                    onContinue(status.guest)
                  }}
                  width={['140px']}
                >
                  <Trans>Continue</Trans>
                </Button>
                <LinkButton
                  type="button"
                  onClick={() => {
                    mixpanel.outOfAreaModal({ type: 'change' })
                    onSetZipCodeNotInArea(false)
                  }}
                  mt={20}
                >
                  <Trans>Enter different zip code</Trans>
                </LinkButton>
              </>
            )}
          </Form>
        )}
      />
    </ModalBody>
  )
}

ZipCodeForm.propTypes = {
  formStatus: PropTypes.object,
  isChanging: PropTypes.bool,
  zipCode: PropTypes.string,
  zipCodeNotInArea: PropTypes.bool.isRequired,
  onSetZipCodeNotInArea: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  onClose: PropTypes.func,
}

export default ZipCodeForm

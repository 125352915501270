import { lifecycle, mapProps, compose, withProps } from 'recompose'
import { Form } from './styled'

import { scrollToElement } from 'utils/scroll'

export { SubmitButton } from './SubmitButton'

export default compose(
  lifecycle({
    componentDidMount() {
      const { isSubmitting, isValid } = this.props

      if (
        typeof isSubmitting === 'undefined' ||
        typeof isValid === 'undefined'
      ) {
        console.warn(
          'Form is missing isSubmitting and/or isValid props. These are needed for smooth scrolling to error.'
        )
      }
    },
    componentDidUpdate(prevProps) {
      if (this.props.onUpdate) {
        this.props.onUpdate()
      }

      // TODO: handle errors from API
      if (
        prevProps.isSubmitting &&
        !this.props.isSubmitting &&
        !this.props.isValid
      ) {
        const errorElement = document.querySelector('.error')
        if (!errorElement) return
        scrollToElement({
          target: errorElement,
          isCancellable: false,
        }).then(() => {
          const input = errorElement.parentNode.querySelector('input')
          if (input) {
            input.focus()
          }
        })
      }
    },
  }),
  withProps({ noValidate: true }),
  mapProps(({ onUpdate, isSubmitting, isValid, ...rest }) => ({ ...rest })) // eslint-disable-line
)(Form)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Media from 'react-media'
import { createStructuredSelector } from 'reselect'
import { DateTime } from 'luxon'

import {
  selectCurrentMemberID,
  selectMemberAddress,
  selectGuestZipCode,
  selectGuestHomeSize,
  selectGuestContractEndDate,
} from 'modules/members/selectors'
import { updateMemberSuccess } from 'modules/members/actions'
import { getCostsSuccess } from 'pages/Wholesale/actions'
import { getHistorySuccess } from 'pages/ElectricityUsage/actions'
import { appendEmptyData } from 'pages/ElectricityUsage/helpers'

import * as API from 'api'
import * as mixpanel from 'utils/mixpanel'

import BlurModal from 'components/BlurModal'
import { breakpoints } from 'styles/mq'

import ZipCodeForm from './ZipCodeForm'
import SelectHomeSize from './SelectHomeSize'
import ContractEndDate from './ContractEndDate'

import { formTypes } from './constants'

class ModalForm extends Component {
  state = {
    formStatus: { form: true },
    zipCodeNotInArea: false,
  }

  componentDidMount() {
    const {
      modalFormType,
      location: { pathname },
    } = this.props

    mixpanel.guestModals({ modalFormType, pathname })
  }

  componentDidUpdate() {
    const {
      location: { pathname },
      modalFormType,
    } = this.props
    const { formStatus } = this.state
    if (modalFormType === formTypes.SET_HOME_SIZE && formStatus.form) {
      mixpanel.guestModals({
        modalFormType,
        pathname,
      })
    }
  }

  handleSetFormStatus = formStatus => this.setState({ formStatus })

  handleSetZipCodeNotInArea = zipCodeNotInArea => {
    const {
      location: { pathname },
    } = this.props
    mixpanel.guestModals({
      modalFormType: formTypes.OUT_OF_AREA,
      pathname,
    })
    this.setState({ zipCodeNotInArea })
  }

  handleZipCodeSubmit = async (values, formikActions) => {
    const {
      modalFormType,
      memberID,
      actions,
      location: { pathname },
    } = this.props

    let guest = null
    try {
      const { zip_code } = values
      guest = await API.updateGuest({ memberID, zip_code })
      mixpanel.updateZipcode({ pathname, zipcode: zip_code })
    } catch (error) {
      formikActions.setFieldError('zip_code', error.message)
      formikActions.setSubmitting(false)
    }
    // Out of area zipcode
    if (guest.provided_zip_code !== guest.zip_code) {
      formikActions.setSubmitting(false)
      formikActions.setStatus({ guest })
      if (modalFormType === formTypes.CHANGE_ZIP_CODE) {
        actions.updateMemberSuccess(guest, 'updateGuest')
      }
      this.handleSetZipCodeNotInArea(true)
      return
    }

    this.handleEndZipCodeUpdate(guest)
  }

  handleEndZipCodeUpdate = async updatedGuest => {
    const { onClose, actions } = this.props
    this.handleSetFormStatus({ updating: true })
    if (updatedGuest) {
      const response = await API.getNow({
        settlement_point: updatedGuest.settlement_point,
      })
      const refreshAt = DateTime.local().plus({
        seconds: Number(response.seconds_until_refresh),
      })
      actions.getCostsSuccess({
        ...response,
        refreshAt: refreshAt.toString(),
      })
      this.handleSetFormStatus({ complete: true })
      setTimeout(() => {
        actions.updateMemberSuccess(updatedGuest, 'updateGuest')
        onClose()
      }, 1000)
    } else {
      await new Promise(resolve => {
        setTimeout(resolve, 1000)
      })
      this.handleSetFormStatus({ complete: true })
      setTimeout(onClose, 1000)
    }
  }

  handleHomeSizeSubmit = async (values, formikActions) => {
    const {
      modalFormType,
      memberID,
      onClose,
      actions,
      location: { pathname },
    } = this.props
    try {
      const guest = await API.updateGuest({ memberID, ...values })
      mixpanel.updateHomeSize({ pathname, size: values.home_size })

      if (modalFormType === formTypes.SET_HOME_SIZE) {
        await this.handleRepopulateData(guest)
      } else {
        this.handleSetFormStatus({ updating: true })
        await new Promise(resolve => {
          setTimeout(resolve, 1000)
        })
        this.handleSetFormStatus({ complete: true })
        setTimeout(() => {
          actions.updateMemberSuccess(guest, 'updateGuest')
          onClose()
        }, 1000)
      }
    } catch (error) {
      formikActions.setFieldError('message', error.message)
      formikActions.setSubmitting(false)
    }
  }

  handleRepopulateData = async guest => {
    const { location, memberID, actions } = this.props
    this.handleSetFormStatus({ updating: true })
    try {
      const { pathname } = location
      let response = null

      if (pathname.startsWith('/usage')) {
        response = await API.getGuestHistory({
          meterID: guest.profileID,
        })
        actions.getHistorySuccess(guest.profileID, appendEmptyData(response))
      }
      if (pathname.startsWith('/statement')) {
        const { month, year } = DateTime.local().toObject()
        response = await API.getGuestSummary({
          memberID,
          month,
          year,
        })
      }
      if (pathname.startsWith('/savings')) {
        await actions.getSavings({ memberID })
      }
    } catch (error) {
      this.handleSetFormStatus({
        error: {
          onTryAgain: () => {
            this.handleRepopulateData(guest)
          },
        },
      })
      return
    }

    this.handleSetFormStatus({ complete: true })
    setTimeout(() => {
      actions.updateMemberSuccess(guest, 'updateGuest')
    }, 1000)
  }

  handleContractEndDateSubmit = async (values, formikActions) => {
    const { memberID, onClose, actions } = this.props
    const { endMonth, endYear } = values
    const date = DateTime.local(Number(endYear), Number(endMonth))
    const contract_end_date = date.toFormat('yyyy-LL-dd')

    try {
      const guest = await API.updateGuest({
        memberID,
        contract_end_date,
      })
      actions.updateMemberSuccess(guest, 'updateGuest')
      mixpanel.updateContractExp({ contractExpDate: contract_end_date })
      onClose()
    } catch (error) {
      formikActions.setFieldError('message', error.message)
      formikActions.setSubmitting(false)
    }
  }

  render() {
    const {
      modalFormType,
      onClose,
      address,
      zipCode,
      homeSize,
      contractEndDate,
    } = this.props

    const { formStatus, zipCodeNotInArea } = this.state

    switch (modalFormType) {
      case formTypes.WELCOME:
        return (
          <Media query={{ minWidth: breakpoints.tablet }}>
            {matches => (
              <BlurModal parentElementId={matches ? 'dashboard' : 'app'}>
                <ZipCodeForm
                  formStatus={formStatus}
                  zipCode={address ? address.postal_code : zipCode}
                  zipCodeNotInArea={zipCodeNotInArea}
                  onSetZipCodeNotInArea={this.handleSetZipCodeNotInArea}
                  onSubmit={this.handleZipCodeSubmit}
                  onContinue={this.handleEndZipCodeUpdate}
                />
              </BlurModal>
            )}
          </Media>
        )
      case formTypes.SET_HOME_SIZE:
        return (
          <BlurModal parentElementId="main">
            <SelectHomeSize
              formStatus={formStatus}
              setFormStatus={this.handleSetFormStatus}
              onSubmit={this.handleHomeSizeSubmit}
            />
          </BlurModal>
        )
      case formTypes.CONTRACT_END_DATE:
        return (
          <BlurModal parentElementId="dashboard" onOutsideClick={onClose}>
            <ContractEndDate
              formStatus={formStatus}
              contractEndDate={contractEndDate}
              onSubmit={this.handleContractEndDateSubmit}
              onClose={onClose}
            />
          </BlurModal>
        )
      case formTypes.CHANGE_HOME_SIZE:
        return (
          <BlurModal parentElementId="dashboard" onOutsideClick={onClose}>
            <SelectHomeSize
              formStatus={formStatus}
              homeSize={homeSize}
              onSubmit={this.handleHomeSizeSubmit}
              onClose={onClose}
            />
          </BlurModal>
        )
      case formTypes.CHANGE_ZIP_CODE:
        return (
          <BlurModal parentElementId="dashboard" onOutsideClick={onClose}>
            <ZipCodeForm
              formStatus={formStatus}
              isChanging
              zipCode={zipCode}
              zipCodeNotInArea={zipCodeNotInArea}
              onSetZipCodeNotInArea={this.handleSetZipCodeNotInArea}
              onSubmit={this.handleZipCodeSubmit}
              onContinue={this.handleEndZipCodeUpdate}
              onClose={onClose}
            />
          </BlurModal>
        )
      default:
        return null
    }
  }
}

ModalForm.propTypes = {
  location: PropTypes.object.isRequired,
  modalFormType: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  memberID: PropTypes.string.isRequired,
  address: PropTypes.object,
  zipCode: PropTypes.string,
  homeSize: PropTypes.string,
  contractEndDate: PropTypes.string,
}

const mapStateToProps = createStructuredSelector({
  memberID: selectCurrentMemberID,
  address: selectMemberAddress,
  zipCode: selectGuestZipCode,
  homeSize: selectGuestHomeSize,
  contractEndDate: selectGuestContractEndDate,
})

const mapDispatch = dispatch => ({
  actions: {
    getSavings: dispatch.savings.getSavings,
    ...bindActionCreators(
      {
        updateMemberSuccess,
        getCostsSuccess,
        getHistorySuccess,
      },
      dispatch
    ),
  },
})

export default connect(mapStateToProps, mapDispatch)(ModalForm)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { Flex, Box } from 'grid-styled'
import Media from 'react-media'
import SwipeableViews from 'react-swipeable-views'
import { Trans } from '@lingui/macro'

import { breakpoints } from 'styles/mq'
import { Button } from 'components/buttons'
import ErrorMessage from 'components/Form/ErrorMessage'

import ModalBody from '../ModalBody'
import HomeSizeRadio from './HomeSizeRadio'
import Pagination from './Pagination'

const styles = {
  root: {
    margin: '0 -15px',
    padding: '0 25%',
  },
  slideContainer: {
    padding: '0 10px',
  },
}

class SelectHomeSize extends Component {
  constructor(props) {
    super(props)
    let activeIndex = 1

    if (props.homeSize === 'small') {
      activeIndex = 0
    }
    if (props.homeSize === 'medium') {
      activeIndex = 1
    }
    if (props.homeSize === 'large') {
      activeIndex = 2
    }
    this.state = {
      activeIndex,
    }
  }

  handleIndexChange = index => {
    this.setState({ activeIndex: index })
  }

  render() {
    const {
      formStatus,
      setFormStatus,
      onSubmit,
      onClose,
      homeSize,
    } = this.props
    const { activeIndex } = this.state
    return (
      <ModalBody
        dataTestId="homeSizeModal"
        formStatus={formStatus}
        setFormStatus={setFormStatus}
        title={<Trans>What Size is Your Home?</Trans>}
        subTitle={
          <Trans>
            Select the option that best fits your home so we can give you a
            better estimate of your usage and savings with Griddy.
          </Trans>
        }
        onClose={onClose}
      >
        <Formik
          initialValues={{
            home_size: homeSize,
          }}
          onSubmit={onSubmit}
          render={({ isSubmitting, values, errors }) => (
            <Form style={{ width: '100%' }}>
              <Media query={{ minWidth: breakpoints.tablet }}>
                {matches =>
                  matches ? (
                    <Flex mt="20px" justifyContent="center">
                      <HomeSizeRadio value="small" />
                      <HomeSizeRadio value="medium" />
                      <HomeSizeRadio value="large" />
                    </Flex>
                  ) : (
                    <Box mt="20px">
                      <SwipeableViews
                        style={styles.root}
                        slideStyle={styles.slideContainer}
                        index={activeIndex}
                        onChangeIndex={this.handleIndexChange}
                      >
                        <HomeSizeRadio
                          value="small"
                          onClick={() => this.handleIndexChange(0)}
                        />
                        <HomeSizeRadio
                          value="medium"
                          onClick={() => this.handleIndexChange(1)}
                        />
                        <HomeSizeRadio
                          value="large"
                          onClick={() => this.handleIndexChange(2)}
                        />
                      </SwipeableViews>
                      <Pagination activeIndex={activeIndex} />
                    </Box>
                  )
                }
              </Media>
              <Flex
                style={{ position: 'relative' }}
                pt="20px"
                flexDirection="column"
                alignItems="center"
              >
                {errors.message && (
                  <ErrorMessage
                    position="absolute"
                    textAlign="center"
                    top={['0']}
                  >
                    {errors.message}
                  </ErrorMessage>
                )}
                <Button
                  data-testid="selectButton"
                  disabled={!values.home_size}
                  loading={isSubmitting}
                  width="154px"
                >
                  <Trans>Select</Trans>
                </Button>
              </Flex>
            </Form>
          )}
        />
      </ModalBody>
    )
  }
}

SelectHomeSize.propTypes = {
  formStatus: PropTypes.object.isRequired,
  setFormStatus: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  homeSize: PropTypes.string,
}

export default SelectHomeSize

import { takeLatest, call, put, select, fork } from 'redux-saga/effects'

import * as API from 'api'

import { selectCurrentMemberID } from 'modules/members/selectors'

import { LOAD_STATEMENT_PAGE, SELECT_STATEMENT_DATE } from './constants'
import {
  getStatementRequest,
  getStatementSuccess,
  getStatementFailure,
  setStatementDate,
} from './actions'
import { getAccountID } from 'containers/App/saga'

export function* statementSaga(data) {
  yield put(getStatementRequest())
  // const { payload } = data
  let memID

  if (data) {
    memID = data.payload.memberID
  }

  const storeMemID = yield select(selectCurrentMemberID)
  const memberID = storeMemID ? storeMemID : memID
  if (!memberID) {
    return
  }

  const accountID = yield call(getAccountID, { memberID })
  yield call(getStatementSaga, { memberID, accountID })
}

export function* getStatementSaga({ memberID, accountID }) {
  try {
    const apiEndpoint = API.getStatements
    const response = yield call(apiEndpoint, {
      memberID,
      accountID,
      status: 'sent',
    })
    if (response.statements && response.statements[0]) {
      const date = response.statements[0].statement_date
      yield put(setStatementDate(date))
    }
    yield put(getStatementSuccess(response.statements))
  } catch (error) {
    yield put(getStatementFailure(error))
  }
}

function* selectStatementDateSaga({ date }) {
  yield put(setStatementDate(date))
}

export default function* defaultSaga() {
  yield fork(statementSaga)
  yield takeLatest(SELECT_STATEMENT_DATE, selectStatementDateSaga)
  yield takeLatest(LOAD_STATEMENT_PAGE, statementSaga)
}

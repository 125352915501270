import { createSelector } from 'reselect'

const selectPriceLock = state => state.priceLock

export const selectIsPriceLocked = createSelector(
  selectPriceLock,
  priceLock => priceLock.priceLockStatus === 'locked'
)

export const selectMeterID = createSelector(
  selectPriceLock,
  priceLock => priceLock.meterID
)

export const selectMemberID = createSelector(
  selectPriceLock,
  priceLock => priceLock.memberID
)

export const selectEligibleUnlockDate = createSelector(
  selectPriceLock,
  priceLock => priceLock.eligibleUnlockDate
)

export const selectSummerAutoLock = createSelector(
  selectPriceLock,
  priceLock => priceLock.summerAutoLock
)

export const selectPriceLockRate = createSelector(
  selectPriceLock,
  priceLock => priceLock.priceLockRate
)

export const selectLoading = createSelector(
  selectPriceLock,
  priceLock => priceLock.loading
)

export const selectErrorMessage = createSelector(
  selectPriceLock,
  priceLock => priceLock.errorMessage
)

export const selectPriceLockRateEndDate = createSelector(
  selectPriceLockRate,
  priceLockRate => priceLockRate.end_date
)

export const selectPriceLockRatePrice = createSelector(
  selectPriceLockRate,
  priceLockRate => priceLockRate.display_rate
)

export const selectEfl = createSelector(
  selectPriceLockRate,
  priceLockRate => priceLockRate.efl
)

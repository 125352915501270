import styled, { css } from 'styled-components'
import { width, space, fontWeight } from 'styled-system'

import mq from 'styles/mq'
import { color, font, input, zIndex } from 'styles/variables'

import checkmark from 'assets/images/icons/checkmark.svg'

export const Wrapper = styled.div`
  position: relative;
  width: 200px;
  margin: ${props => props.m || '0px'};
  ${width};
`

export const Button = styled.button`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  font-size: 1.6rem;
  color: ${color.base};
  border-radius: 4px;
  outline: none;
  background: #fff;
  border: 1px solid ${props => (props.isOpen ? color.secondary : color.gray)};
  transition: border 0.3s ease;

  ${mq.tablet`
    &:hover {
      border: 1px solid ${color.secondary};
    }
  `};

  ${space};
  ${fontWeight};
`

export const List = styled.ul`
  position: absolute;
  right: 0;
  width: 100%;
  list-style: none;
  padding: 4px 0;
  margin-top: 8px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid ${input.borderColor};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  transition: 0.2s visibility, 0.2s opacity;
  z-index: ${zIndex.enrollmentHelp};
  max-height: 200px;
  overflow: auto;
`

export const ListItem = styled.li`
  display: block;
  text-align: left;
  position: relative;

  ${props =>
    props.isActive &&
    css`
      ::after {
        content: '';
        background: url(${checkmark}) no-repeat center center;
        position: absolute;
        top: calc(50% - 5px);
        right: 10px;
        width: 10px;
        height: 10px;
      }
    `};
`

export const ListButton = styled.button`
  width: 100%;
  font-family: ${font.normal};
  text-align: left;
  padding: 7px 10px;
  color: ${color.base};
  transition: 0.2s background;
  font-size: 1.4rem;
  cursor: pointer;

  &:hover {
    background-color: #f2f3f6;
  }
`

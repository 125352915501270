import {
  take,
  takeLatest,
  call,
  select,
  fork,
  put,
  all,
  cancel,
} from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { push } from 'react-router-redux'
import { getLoginData } from 'containers/App/saga'
import { detectCardType } from 'utils/utils'
import { removeMask } from 'utils/format'

import { makeSelectUserID } from 'modules/user/selectors'
import { selectAccountID } from 'pages/Account/selectors'
import {
  selectCurrentMember,
  selectCurrentMemberID,
  selectTimeZone,
} from 'modules/members/selectors'
import {
  setCurrentMember,
  updateMemberRequest,
  updateMemberSuccess,
  updateMemberFailure,
} from 'modules/members/actions'

import {
  getNetMeterBalanceSuccess,
  getNetMeterBalanceFailure,
} from 'pages/Usage/actions'

import { renewalSaga } from 'pages/Renewal/saga'
import {
  addCardSuccess,
  addCardFailure,
  getAccountRequest,
  getAccountSuccess,
  getAccountFailure,
  getCardsSuccess,
  deleteCardSuccess,
  setPrimaryCardSuccess,
  updateUserRequest,
  updateUserSuccess,
  updateUserFailure,
  addFundsSuccess,
  addFundsFailure,
  showSubmitSuccessModal,
  hideSubmitSuccessModal,
  getDeposits,
  clearDeposits,
  getDepositsRequest,
  getDepositsSuccess,
  getDepositsFailure,
  hasMoreDeposits,
  depositsPaginationComplete,
  getProductsRequest,
  getProductsSuccess,
  getProductsFailure,
  submitChasePaymentCustomFormFailure,
  updateBillingAutopayRequest,
  updateAutoPaySuccess,
  updateAutoPayFailure,
  updateBillingStatementDeliveryRequest,
  updateBillingStatementDeliverySuccess,
  updateBillingStatementDeliveryFailure,
  getAccountBalanceSuccess,
  getAccountBalanceFailure,
  getAccountStatementSuccess,
  getAccountStatementFailure,
  showChangeAutoPayModal,
  getNetMeteringHistorySuccess,
  getNetMeteringHistoryFailure,
  clearNetMeteringHistory,
  getNetMeteringHistory,
} from './actions'
import {
  SUBMIT_UPDATE_AUTO_PAY,
  SUBMIT_NEW_BILLING_ADDRESS_FORM,
  SUBMIT_NEW_EMAIL_FORM,
  SUBMIT_RECHARGE_FORM,
  SUBMIT_NEW_PHONE_FORM,
  SUBMIT_NEW_PASSWORD_FORM,
  SUBMIT_ADD_CARD_FORM,
  DELETE_CARD,
  SET_PRIMARY_CARD,
  SUBMIT_ADD_FUNDS_FORM,
  GET_DEPOSITS,
  RESET_DEPOSITS,
  GET_PRODUCTS,
  SUBMIT_CHASE_PAYMENT_CUSTOM_FORM,
  SUBMIT_ICHECK_PAYMENT_CUSTOM_FORM,
  LOAD_ACCOUNT_PAGE,
  SUBMIT_LANGUAGE_PREFERENCE,
  SUBMIT_UPDATE_STATEMENT_DELIVERY,
  GET_NET_METERING_HISTORY,
} from './constants'

// eslint-disable-next-line
import {
  getUserSaga,
  getAccountDetails,
  getMeterSaga,
} from 'containers/App/saga'
import handleError from 'api/apiErrors'
import * as API from 'api'
import { PAYMENT_TYPE, ADD_FUNDS_MODE } from 'utils/constants'
import { CHASE_SUBSCRIBER_ID, CHASE_ENABLE_PIE } from 'config'

import React from 'react'
import { Trans } from '@lingui/macro'
import { DateTime } from 'luxon'

// http://localhost:3000/account?client=Android&userID=118&memberID=f022c440-0c6f-4bf3-8ce5-c16d17868eee
export function* accountSaga(data) {
  let qMemberID
  let qUserID

  if (data) {
    qMemberID = data.payload.memberID
    qUserID = data.payload.userID
  }

  yield put(getAccountRequest())
  const storeMemberID = yield select(selectCurrentMemberID) // 'f022c440-0c6f-4bf3-8ce5-c16d17868eee'
  const storeUserID = yield select(makeSelectUserID()) // '118' //

  // if store is empty implies loading direct from url for mobile webview then load data using data passed in query params
  if (!storeUserID || !storeMemberID) {
    yield call(getLoginData, {
      payload: { userID: qUserID, memberID: qMemberID },
    })
  }
  const memberID = storeMemberID || qMemberID
  const userID = storeUserID || qUserID

  return yield call(getAccountSaga, { memberID, userID })
}

export function* getAccountSaga({ memberID, userID }) {
  try {
    const [account] = yield all([
      call(getAccountDetails, { memberID }),
      call(getCardsSaga, { userID, memberID }),
      call(getMeterSaga, { memberID }),
      call(getAccountBalanceSaga, { memberID }),
      call(getNetMeterBalanceSaga, { memberID }),
      call(getAccountStatementSaga, { memberID }),
    ])
    const getDeposits = yield fork(initDeposits, { memberID })
    const getNetMetering = yield fork(initNetMetering, { memberID })
    yield fork(getProductsSaga, { memberID })
    yield fork(renewalSaga, { memberID })
    yield put(getAccountSuccess({ ...account }))
    yield take(RESET_DEPOSITS)
    yield cancel(getDeposits)
    yield cancel(getNetMetering)
  } catch (error) {
    yield put(getAccountFailure(error))
  }
}

function* initDeposits({ memberID }) {
  yield put(clearDeposits())
  const paginateDeposits = yield fork(getDepositsSaga, { memberID })
  yield put(getDeposits()) // triggers getDepositsSaga to request the fisrt page of deposits
  yield take(RESET_DEPOSITS)
  yield cancel(paginateDeposits)
}

function* initNetMetering({ memberID }) {
  yield put(clearNetMeteringHistory())
  const paginateNetMetering = yield fork(getNetMeteringHistorySaga, { memberID })
  yield put(getNetMeteringHistory({ memberID })) // trigger first page
  yield take(RESET_DEPOSITS)
  yield cancel(paginateNetMetering)
}

export function* getAccountBalanceSaga({ memberID }) {
  try {
    const res = yield call(API.getAccountBalance, {
      member_id: memberID,
    })
    yield put(getAccountBalanceSuccess(res))
  } catch (err) {
    yield put(getAccountBalanceFailure(err))
    console.log(err)
  }
}

export function* getAccountStatementSaga({ memberID }) {
  try {
    const id = yield select(selectAccountID)
    const res = yield call(API.getStatements, {
      memberID,
      accountID: id,
      status: 'sent',
    })
    yield put(getAccountStatementSuccess(res))
  } catch (err) {
    yield put(getAccountStatementFailure(err))
    console.log(err)
  }
}

export function* getProductsSaga({ memberID }) {
  yield put(getProductsRequest())
  try {
    const res = yield call(API.getProducts, {
      memberID,
    })
    const products = res.list
    let latestProduct = {}
    // filter for latest time zone
    const timezone = yield select(selectTimeZone)
    for (let i = 0; i < products.length; i++) {
      let p = products[i]
      const start_date = p.start_date.slice(0, 10)
      const end_date = p.end_date === '' ? 'N/A' : p.end_date.slice(0, 10)
      const today = DateTime.local()
        .setZone(timezone)
        .toFormat('yyyy-MM-dd')

      if (
        Date.parse(today) >= Date.parse(start_date) &&
        (Date.parse(today) <= Date.parse(end_date) || p.end_date === '')
      ) {
        latestProduct = p
      }
    }
    yield put(getProductsSuccess({ products, latestProduct }))
  } catch (err) {
    put(getProductsFailure(err))
  }
}

function* getNetMeterBalanceSaga({ memberID }) {
  try {
    const res = yield call(API.getNetMeteringBankBalance, {
      member_id: memberID,
    })
    yield put(getNetMeterBalanceSuccess(res))
  } catch (err) {
    yield put(getNetMeterBalanceFailure(err))
  }
}

function* getNetMeteringHistorySaga({ memberID }) {
  let currentPage = 0
  let hasNext = true
  const limit = 50

  while (hasNext) {
    // GET_NET_METERING_HISTORY sets the loading state in the reducer
    yield take(GET_NET_METERING_HISTORY) // this pauses the saga until the GET_NET_METERING_HISTORY action is dispatched
    try {
      const res = yield call(API.getNetMeteringBankHistory, {
        member_id: memberID,
        page: currentPage + 1,
        limit,
      })
      currentPage = parseInt(res.page)
      hasNext = res.has_next

      yield put(getNetMeteringHistorySuccess(res))
    } catch (err) {
      hasNext = false
      yield put(getNetMeteringHistoryFailure(err))
    }
  }
}

function* getDepositsSaga({ memberID }) {
  let currentPage = 0
  let hasNext = true
  const limit = 25
  while (hasNext) {
    yield take(GET_DEPOSITS) // this pauses the saga until the GET_DEPOSITS action is dispatched
    yield put(getDepositsRequest())
    try {
      const nextPage = currentPage + 1
      const { deposits, page, has_next } = yield call(API.getAccountDeposits, {
        memberID,
        page: nextPage,
        limit,
      })
      currentPage = parseInt(page)
      hasNext = has_next
      if (hasNext) {
        yield put(hasMoreDeposits())
      }
      yield put(getDepositsSuccess({ deposits }))
    } catch (err) {
      hasNext = false
      put(getDepositsFailure(err)) // error state needs designed.  Currently this triggers no change in the UI
    }
  }
  yield put(depositsPaginationComplete())
}

function* getCardsSaga({ userID, memberID }) {
  try {
    const res = yield call(API.getCards, { user_id: userID, member_id: memberID })
    yield put(getCardsSuccess({ userID, cards: res.card }))
  } catch (error) {
    console.warn(error)
  }
}

function* updateUserSaga({ body, setters }) {
  const userID = yield select(makeSelectUserID())
  yield put(updateUserRequest())
  try {
    yield call(API.updateUser, { userID, ...body })
    yield call(getUserSaga, { userID })
    yield put(updateUserSuccess(body))
    yield put(push('/account'))
  } catch (error) {
    yield put(updateUserFailure(error))
    yield call(handleError, setters, error, Object.keys(body)[0])
    if (setters.toggleErrorModal) {
      yield call(setters.toggleErrorModal)
    }
  }
}

function* updateAccountSaga({ body, setters }) {
  const id = yield select(selectAccountID)
  const memberID = yield select(selectCurrentMemberID)
  yield put(updateUserRequest())
  try {
    yield call(API.updateAccount, { memberID, id: parseInt(id), ...body })
    yield put(getAccountSuccess(body))
    yield put(push('/account'))
  } catch (error) {
    yield put(updateUserFailure(error))
    yield call(handleError, setters, error, Object.keys(body)[0])
    if (setters.toggleErrorModal) {
      yield call(setters.toggleErrorModal)
    }
  }
}

function* updateAutoPay({ payload }) {
  const memberID = yield select(selectCurrentMemberID)
  yield put(updateBillingAutopayRequest())
  const { autopay, location } = payload
  const body = {
    memberID,
    autopay,
  }
  try {
    const res = yield call(API.updateAutoPay, body)
    yield put(updateAutoPaySuccess(res.autopay))
    if (location !== '/account') yield put(push('/account'))
  } catch (error) {
    yield put(updateAutoPayFailure())
  }
}

function* updateStatementDelivery({ payload }) {
  const memberID = yield select(selectCurrentMemberID)
  yield put(updateBillingStatementDeliveryRequest())
  const { statement_delivery, location } = payload
  const body = {
    memberID,
    statement_delivery: statement_delivery ? 'email' : 'paper',
  }
  try {
    const res = yield call(API.updateStatementDelivery, body)
    yield put(updateBillingStatementDeliverySuccess(res.statement_delivery))
    if (location !== '/account') yield put(push('/account'))
  } catch (error) {
    yield put(updateBillingStatementDeliveryFailure())
  }
}

function* updateRechargeSaga({ body, setters }) {
  const member = yield select(selectCurrentMember)
  const memberID = yield select(selectCurrentMemberID)
  yield put(updateMemberRequest())
  try {
    yield call(API.updateMember, { memberID, ...body })
    const updatedMember = { ...member, ...body }
    yield put(setCurrentMember(updatedMember))
    yield put(updateMemberSuccess(updatedMember, 'updateRecharge'))
    yield put(push('/account'))
  } catch (error) {
    yield put(updateMemberFailure(error))
    yield call(handleError, setters, error, Object.keys(body)[0])
  }
}

function* addCardSaga({ token, isPrimary, setters }) {
  const memberID = yield select(selectCurrentMemberID)
  try {
    yield call(
      API.addCard,
      { is_default: isPrimary, memberID, stripeToken: token.id } // eslint-disable-line camelcase
    )
    yield put(addCardSuccess(isPrimary))
    yield call(getCardsSaga, { memberID })
    yield put(push('/account/payment'))
  } catch (error) {
    yield put(addCardFailure(isPrimary))
    yield call(handleError, setters, error, 'apiCardError')
  }
}

function* deleteCardSaga({ cardID, paymentMethodID, setters }) {
  const memberID = yield select(selectCurrentMemberID)
  try {
    yield call(API.deleteCard, { cardID, memberID, paymentMethodID })
    yield put(deleteCardSuccess({ memberID, cardID }))
    yield call(setters.setLoading, false)
    yield call(setters.closeModal)
  } catch (error) {
    console.warn(error)
  }
}

function* setPrimaryCardSaga({ cardID, paymentMethodID, setters }) {
  const memberID = yield select(selectCurrentMemberID)
  try {
    yield call(API.updateCard, {
      cardID,
      memberID,
      paymentMethodID,
      set_as_default: true,
    }) // eslint-disable-line camelcase
    yield put(setPrimaryCardSuccess({ memberID, cardID }))
    yield call(setters.setLoading, false)
    yield call(setters.closeModal)
  } catch (error) {
    console.warn(error)
  }
}

function* addFundsSaga({ body, setters }) {
  const userID = yield select(makeSelectUserID())
  const memberID = yield select(selectCurrentMemberID)
  let paymentSuccessfull = false

  try {
    if (body.selectedMode === ADD_FUNDS_MODE.EXISTING_METHOD) {
      const paymentSubType = 'manual_payment'
      const resp = yield call(API.addFunds, {
        member_id: memberID,
        ...body,
        amount: body.new_payment_amount,
        paymentSubType,
        hashID: String(Date.now()),
      })

      if (resp && resp.success) {
        paymentSuccessfull = true
      }
    } else {
      paymentSuccessfull = yield call(createChasePaymentMethodSaga, {
        ...body,
        amount: body.new_payment_amount,
      })

      if (paymentSuccessfull) {
        yield call(getCardsSaga, { userID, memberID })
      }
    }
  } catch (error) {
    console.warn(error)
  } finally {
    if (paymentSuccessfull) {
      yield put({
        type: RESET_DEPOSITS,
      })
      yield fork(initDeposits, { memberID })
      yield fork(getAccountBalanceSaga, { memberID })

      yield put(addFundsSuccess())
      yield put(
        showSubmitSuccessModal(<Trans>Funds successfully added!</Trans>)
      )
      yield put(push('/account'))
      yield call(delay, 1000)
      yield put(hideSubmitSuccessModal())
      if (setters.showAutoPayModal) {
        yield put(showChangeAutoPayModal())
      }
    } else {
      yield call(setters.toggleErrorModal)
      yield call(setters.setSubmitting, false)
      yield put(addFundsFailure())
    }
  }
}

function* createChasePaymentMethodSaga({
  name,
  zipcode,
  isPrimary,
  paymentType,
  expirationDate,
  cardNumber,
  cvc,
  accountType,
  accountNumber,
  routingNumber,
  amount = 0,
  shouldSavePaymentMethod = false,
}) {
  const memberID = yield select(selectCurrentMemberID)
  const userID = yield select(makeSelectUserID())
  const timestamp = new Date().getTime()

  const reqBody = {
    memberID,
    order_id: `MP${userID}_${timestamp}`,
    name,
    zip: zipcode,
    amount,
    is_default: isPrimary,
    skip_saving_profile: !shouldSavePaymentMethod,
  }

  if (paymentType === PAYMENT_TYPE.CC) {
    // make sure card number is just in numbers
    cardNumber = removeMask(cardNumber)

    const exp = expirationDate.split('/')
    reqBody.payment_type = detectCardType(cardNumber)

    // encrypt card
    const useEncryption = CHASE_ENABLE_PIE
    let encryptedCard = []
    if (useEncryption) {
      encryptedCard = window.ProtectPANandCVV(cardNumber, cvc, true)

      const pageEncryption = {
        pie_format_id: `${window.PIE.L}${window.PIE.E}`,
        pie_integrity_check: encryptedCard[2],
        pie_key_id: window.PIE.key_id,
        pie_phase_id: `${window.PIE.phase}`,
        pie_mode: 'FPE',
        pie_subscriber_id: CHASE_SUBSCRIBER_ID,
      }

      reqBody.page_encryption = pageEncryption
    }

    reqBody.credit_card_info = {
      cardNumber: useEncryption ? encryptedCard[0] : cardNumber,
      cvc: useEncryption ? encryptedCard[1] : cvc,
      expiration_month: exp[0],
      expiration_year: exp[1],
    }
  }

  if (paymentType === PAYMENT_TYPE.ECP) {
    reqBody.payment_type = PAYMENT_TYPE.ECP
    reqBody.bank_info = {
      account_type: accountType,
      account_number: accountNumber,
      routing_number: routingNumber,
    }
  }

  try {
    const resp = yield call(API.createChasePaymentMethod, reqBody)
    if (resp && resp.payment_method_id !== undefined) {
      return true
    }
    yield put(
      submitChasePaymentCustomFormFailure(
        'something went wrong, Please try again later'
      )
    )
    return false
  } catch (err) {
    yield put(submitChasePaymentCustomFormFailure(err))
    return false
  }
}

function* addCustomChaseFormSaga({ payload }) {
  const userID = yield select(makeSelectUserID())
  const memberID = yield select(selectCurrentMemberID)
  try {
    const resp = yield call(createChasePaymentMethodSaga, payload)
    if (resp) {
      yield put(showSubmitSuccessModal('Payment method added!'))
      yield call(getCardsSaga, { userID, memberID })
      yield put(push('/account/payment'))
      yield call(delay, 3000)
      yield put(hideSubmitSuccessModal())
    }
  } catch (err) {
    yield put(submitChasePaymentCustomFormFailure(err))
  }
}

// submits the vgs form to get tokenized version of card
const callVGS = async (form) => new Promise((resolve) => form.submit('/billing/echo_vgs_data', {}, (status, data) => {
  resolve({ status, data })
}, (validationError) => {
  resolve({ validationError })
}));

const vgsCreator = (form) => callVGS(form)

const getCardType = ({ state }) => {
  const mapping = {
    'amex': 'Amex',
    'discover': 'Discover',
    'mastercard': 'MasterCard',
    'visa': 'Visa',
    'jcb': 'JCB',
  }
  return mapping[state.cardNumber.cardType]

}

function* createICheckPaymentMethodSaga({
  firstName,
  lastName,
  companyName,
  zip,
  isPrimary,
  paymentType,
  expirationDate,
  cardNumber,
  cvc,
  accountType,
  accountNumber,
  routingNumber,
  amount = 0,
  payment_type,
  shouldSavePaymentMethod = false,
}) {

  const memberID = yield select(selectCurrentMemberID)
  const userID = yield select(makeSelectUserID())
  const timestamp = new Date().getTime()

  const reqBody = {
    memberID,
    order_id: `MP${userID}_${timestamp}`,
    firstName,
    lastName,
    companyName,
    zip,
    amount,
    is_default: isPrimary,
    skip_saving_profile: !shouldSavePaymentMethod,
  }

  if (paymentType === PAYMENT_TYPE.CC) {
    const exp = expirationDate.split('/')
    reqBody.payment_type = payment_type

    reqBody.credit_card_info = {
      cardNumber: cardNumber,
      cvc: cvc,
      expiration_month: exp[0].trim(),
      expiration_year: exp[1].trim(),
    }
  }

  if (paymentType === PAYMENT_TYPE.ECP) {
    reqBody.payment_type = PAYMENT_TYPE.ECP
    reqBody.bank_info = {
      account_type: accountType,
      account_number: accountNumber,
      routing_number: routingNumber,
    }
  }

  try {
    const resp = yield call(API.createICheckPaymentMethod, reqBody)
    if (resp && resp.payment_method_id !== undefined) {
      return true
    }
    yield put(
      submitChasePaymentCustomFormFailure(
        'something went wrong, Please try again later'
      )
    )
    return false
  } catch (err) {
    yield put(submitChasePaymentCustomFormFailure(err))
    return false
  }
}

function* addCustomICheckFormSaga({ payload }) {
  const userID = yield select(makeSelectUserID())
  const memberID = yield select(selectCurrentMemberID)

  // update payload with tokenized data
  if (payload.paymentType === PAYMENT_TYPE.CC) {
    const { data } = yield call(vgsCreator, payload.paymentForm)
    const { firstName, lastName, cardNumber, cvc, zip, expiration } = data
    const payment_type = getCardType(payload.paymentForm)
    payload.firstName = firstName
    payload.lastName = lastName
    payload.zip = zip
    payload.cardNumber = cardNumber
    payload.cvc = cvc
    payload.expirationDate = expiration
    payload.payment_type = payment_type
  } else {
    payload.zip = payload.zipcode
  }

  try {
    const resp = yield call(createICheckPaymentMethodSaga, payload)
    if (resp) {
      yield put(showSubmitSuccessModal('Payment method added!'))
      yield call(getCardsSaga, { userID, memberID })
      yield put(push('/account/payment'))
      yield call(delay, 3000)
      yield put(hideSubmitSuccessModal())
    }
  } catch (err) {
    yield put(submitChasePaymentCustomFormFailure(err))
  }
}

export default function* defaultSaga() {
  yield all([
    fork(accountSaga),
    takeLatest(SUBMIT_UPDATE_STATEMENT_DELIVERY, updateStatementDelivery),
    takeLatest(SUBMIT_UPDATE_AUTO_PAY, updateAutoPay),
    takeLatest(SUBMIT_NEW_BILLING_ADDRESS_FORM, updateAccountSaga),
    takeLatest(SUBMIT_NEW_EMAIL_FORM, updateUserSaga),
    takeLatest(SUBMIT_NEW_PHONE_FORM, updateUserSaga),
    takeLatest(SUBMIT_NEW_PASSWORD_FORM, updateUserSaga),
    takeLatest(SUBMIT_LANGUAGE_PREFERENCE, updateUserSaga),
    takeLatest(SUBMIT_RECHARGE_FORM, updateRechargeSaga),
    takeLatest(SUBMIT_ADD_FUNDS_FORM, addFundsSaga),
    takeLatest(SUBMIT_ADD_CARD_FORM, addCardSaga),
    takeLatest(SUBMIT_CHASE_PAYMENT_CUSTOM_FORM, addCustomChaseFormSaga),
    takeLatest(SUBMIT_ICHECK_PAYMENT_CUSTOM_FORM, addCustomICheckFormSaga),
    takeLatest(DELETE_CARD, deleteCardSaga),
    takeLatest(SET_PRIMARY_CARD, setPrimaryCardSaga),
    takeLatest(GET_DEPOSITS, getDepositsSaga),
    takeLatest(GET_PRODUCTS, getProductsSaga),
    takeLatest(LOAD_ACCOUNT_PAGE, accountSaga),
  ])
}

import React from 'react'
import PropTypes from 'prop-types'

const LargeHome = ({ isChecked }) =>
  isChecked ? (
    <svg width="103" height="62" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          d="M5.5 25.5h19v33h-19zM8.888 10.5a.5.5 0 0 0-.442.266l-6.889 13A.5.5 0 0 0 2 24.5h100.002a.5.5 0 0 0 .441-.734l-6.888-13a.5.5 0 0 0-.442-.266H8.888z"
        />
        <path
          d="M2 23.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h100a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H2z"
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
        />
        <rect
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          x="32.5"
          y="1.5"
          width="8"
          height="15"
          rx="1"
        />
        <path
          d="M24.5 22.257V58.5h55V22.257L52 2.614 24.5 22.257z"
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
        />
        <rect
          fill="#7D8D9E"
          fillRule="nonzero"
          x="29"
          y="49"
          width="12"
          height="1"
          rx=".5"
        />
        <rect
          stroke="#7D8D9E"
          fillOpacity=".5"
          fill="#ED0874"
          fillRule="nonzero"
          x="30.5"
          y="35.5"
          width="9"
          height="14"
          rx="1"
        />
        <path fill="#7D8D9E" fillRule="nonzero" d="M30 42h10v1H30z" />
        <rect
          fill="#7D8D9E"
          fillRule="nonzero"
          x="9"
          y="49"
          width="12"
          height="1"
          rx=".5"
        />
        <rect
          stroke="#7D8D9E"
          fillOpacity=".5"
          fill="#ED0874"
          fillRule="nonzero"
          x="10.5"
          y="35.5"
          width="9"
          height="14"
          rx="1"
        />
        <path fill="#7D8D9E" fillRule="nonzero" d="M10 42h10v1H10z" />
        <path
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          d="M79.5 25.5h19v33h-19z"
        />
        <rect
          stroke="#7D8D9E"
          fillOpacity=".5"
          fill="#ED0874"
          fillRule="nonzero"
          x="64.5"
          y="35.5"
          width="9"
          height="14"
          rx="1"
        />
        <rect
          fill="#7D8D9E"
          fillRule="nonzero"
          x="63"
          y="49"
          width="12"
          height="1"
          rx=".5"
        />
        <path fill="#7D8D9E" fillRule="nonzero" d="M64 42h10v1H64z" />
        <rect
          stroke="#7D8D9E"
          fillOpacity=".5"
          fill="#ED0874"
          fillRule="nonzero"
          x="84.5"
          y="35.5"
          width="9"
          height="14"
          rx="1"
        />
        <rect
          fill="#7D8D9E"
          fillRule="nonzero"
          x="83"
          y="49"
          width="12"
          height="1"
          rx=".5"
        />
        <path fill="#7D8D9E" fillRule="nonzero" d="M84 42h10v1H84z" />
        <rect
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          x="45.5"
          y="35.5"
          width="13"
          height="23"
          rx="1"
        />
        <circle fill="#7D8D9E" fillRule="nonzero" cx="48" cy="47" r="1" />
        <path
          d="M21.71 21.883a.5.5 0 0 0-.21.407v4.548a.5.5 0 0 0 .79.408l28.842-20.47a1.5 1.5 0 0 1 1.736 0l28.843 20.47a.5.5 0 0 0 .789-.408V22.29a.5.5 0 0 0-.21-.407l-30-21.29a.5.5 0 0 0-.58 0l-30 21.29z"
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
        />
        <g transform="translate(0 58)">
          <rect
            fill="#7D8D9E"
            transform="matrix(-1 0 0 1 66 0)"
            width="66"
            height="1"
            rx=".5"
          />
          <rect
            fill="#7D8D9E"
            transform="matrix(-1 0 0 1 160 0)"
            x="68"
            width="24"
            height="1"
            rx=".5"
          />
          <rect
            fill="#7D8D9E"
            transform="matrix(-1 0 0 1 196 0)"
            x="93"
            width="10"
            height="1"
            rx=".5"
          />
          <rect
            stroke="#7D8D9E"
            x="69.5"
            y="3.5"
            width="28"
            height="1"
            rx=".5"
          />
          <rect
            stroke="#7D8D9E"
            x="5.5"
            y="3.5"
            width="46"
            height="1"
            rx=".5"
          />
          <rect
            stroke="#7D8D9E"
            x="54.5"
            y="3.5"
            width="3"
            height="1"
            rx=".5"
          />
          <rect
            stroke="#7D8D9E"
            x="59.5"
            y="3.5"
            width="7"
            height="1"
            rx=".5"
          />
        </g>
        <path
          d="M52 15.5a5.5 5.5 0 0 0-5.5 5.5v8a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-8a5.5 5.5 0 0 0-5.5-5.5z"
          stroke="#7D8D9E"
          fillOpacity=".5"
          fill="#ED0874"
          fillRule="nonzero"
        />
        <path fill="#7D8D9E" fillRule="nonzero" d="M46 25h12v1H46z" />
      </g>
    </svg>
  ) : (
    <svg width="103" height="62" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          d="M5.5 25.5h19v33h-19zM8.888 10.5a.5.5 0 0 0-.442.266l-6.889 13A.5.5 0 0 0 2 24.5h100.002a.5.5 0 0 0 .441-.734l-6.888-13a.5.5 0 0 0-.442-.266H8.888z"
        />
        <path
          d="M2 23.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h100a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H2z"
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
        />
        <rect
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          x="32.5"
          y="1.5"
          width="8"
          height="15"
          rx="1"
        />
        <path
          d="M24.5 22.257V58.5h55V22.257L52 2.614 24.5 22.257z"
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
        />
        <rect
          fill="#7D8D9E"
          fillRule="nonzero"
          x="29"
          y="49"
          width="12"
          height="1"
          rx=".5"
        />
        <rect
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          x="30.5"
          y="35.5"
          width="9"
          height="14"
          rx="1"
        />
        <path fill="#7D8D9E" fillRule="nonzero" d="M30 42h10v1H30z" />
        <rect
          fill="#7D8D9E"
          fillRule="nonzero"
          x="9"
          y="49"
          width="12"
          height="1"
          rx=".5"
        />
        <rect
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          x="10.5"
          y="35.5"
          width="9"
          height="14"
          rx="1"
        />
        <path fill="#7D8D9E" fillRule="nonzero" d="M10 42h10v1H10z" />
        <path
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          d="M79.5 25.5h19v33h-19z"
        />
        <rect
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          x="64.5"
          y="35.5"
          width="9"
          height="14"
          rx="1"
        />
        <rect
          fill="#7D8D9E"
          fillRule="nonzero"
          x="63"
          y="49"
          width="12"
          height="1"
          rx=".5"
        />
        <path fill="#7D8D9E" fillRule="nonzero" d="M64 42h10v1H64z" />
        <rect
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          x="84.5"
          y="35.5"
          width="9"
          height="14"
          rx="1"
        />
        <rect
          fill="#7D8D9E"
          fillRule="nonzero"
          x="83"
          y="49"
          width="12"
          height="1"
          rx=".5"
        />
        <path fill="#7D8D9E" fillRule="nonzero" d="M84 42h10v1H84z" />
        <rect
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          x="45.5"
          y="35.5"
          width="13"
          height="23"
          rx="1"
        />
        <circle fill="#7D8D9E" fillRule="nonzero" cx="48" cy="47" r="1" />
        <path
          d="M21.71 21.883a.5.5 0 0 0-.21.407v4.548a.5.5 0 0 0 .79.408l28.842-20.47a1.5 1.5 0 0 1 1.736 0l28.843 20.47a.5.5 0 0 0 .789-.408V22.29a.5.5 0 0 0-.21-.407l-30-21.29a.5.5 0 0 0-.58 0l-30 21.29z"
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
        />
        <g transform="translate(0 58)">
          <rect
            fill="#7D8D9E"
            transform="matrix(-1 0 0 1 66 0)"
            width="66"
            height="1"
            rx=".5"
          />
          <rect
            fill="#7D8D9E"
            transform="matrix(-1 0 0 1 160 0)"
            x="68"
            width="24"
            height="1"
            rx=".5"
          />
          <rect
            fill="#7D8D9E"
            transform="matrix(-1 0 0 1 196 0)"
            x="93"
            width="10"
            height="1"
            rx=".5"
          />
          <rect
            stroke="#7D8D9E"
            x="69.5"
            y="3.5"
            width="28"
            height="1"
            rx=".5"
          />
          <rect
            stroke="#7D8D9E"
            x="5.5"
            y="3.5"
            width="46"
            height="1"
            rx=".5"
          />
          <rect
            stroke="#7D8D9E"
            x="54.5"
            y="3.5"
            width="3"
            height="1"
            rx=".5"
          />
          <rect
            stroke="#7D8D9E"
            x="59.5"
            y="3.5"
            width="7"
            height="1"
            rx=".5"
          />
        </g>
        <path
          d="M52 15.5a5.5 5.5 0 0 0-5.5 5.5v8a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-8a5.5 5.5 0 0 0-5.5-5.5z"
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path fill="#7D8D9E" fillRule="nonzero" d="M46 25h12v1H46z" />
      </g>
    </svg>
  )

LargeHome.propTypes = {
  isChecked: PropTypes.bool,
}

export default LargeHome

import React from 'react'
import PropTypes from 'prop-types'

const Apartment = ({ isChecked }) =>
  isChecked ? (
    <svg width="103" height="62" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(0 7)" fill="none" fillRule="evenodd">
        <rect
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          x="26.5"
          y=".5"
          width="5"
          height="11"
          rx="1"
        />
        <rect
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          x="57.5"
          y=".5"
          width="5"
          height="11"
          rx="1"
        />
        <rect
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          x="88.5"
          y=".5"
          width="5"
          height="11"
          rx="1"
        />
        <path
          d="M7.723 10.12a.5.5 0 0 0-.223.415V51a.5.5 0 0 0 .5.5h25a.5.5 0 0 0 .5-.5V10.535a.5.5 0 0 0-.223-.416l-12.5-8.333a.5.5 0 0 0-.554 0l-12.5 8.333zM38.723 10.12a.5.5 0 0 0-.223.415V51a.5.5 0 0 0 .5.5h25a.5.5 0 0 0 .5-.5V10.535a.5.5 0 0 0-.223-.416l-12.5-8.333a.5.5 0 0 0-.554 0l-12.5 8.333zM69.723 10.12a.5.5 0 0 0-.223.415V51a.5.5 0 0 0 .5.5h25a.5.5 0 0 0 .5-.5V10.535a.5.5 0 0 0-.223-.416l-12.5-8.333a.5.5 0 0 0-.554 0l-12.5 8.333zM33.5 14.5h5v37h-5zM64.5 14.5h5v37h-5z"
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
        />
        <g transform="translate(12 11)" fillRule="nonzero" stroke="#7D8D9E">
          <rect fill="#FFF" x=".5" y=".5" width="6" height="6" rx="1" />
          <rect
            fillOpacity=".5"
            fill="#ED0874"
            x="10.5"
            y=".5"
            width="6"
            height="6"
            rx="1"
          />
          <rect
            fillOpacity=".5"
            fill="#ED0874"
            x=".5"
            y="9.5"
            width="6"
            height="6"
            rx="1"
          />
          <rect fill="#FFF" x="10.5" y="9.5" width="6" height="6" rx="1" />
          <rect fill="#FFF" x=".5" y="18.5" width="6" height="6" rx="1" />
          <rect
            fillOpacity=".5"
            fill="#ED0874"
            x="10.5"
            y="18.5"
            width="6"
            height="6"
            rx="1"
          />
        </g>
        <g transform="translate(43 11)" fillRule="nonzero" stroke="#7D8D9E">
          <rect
            fillOpacity=".5"
            fill="#ED0874"
            x=".5"
            y=".5"
            width="6"
            height="6"
            rx="1"
          />
          <rect fill="#FFF" x="10.5" y=".5" width="6" height="6" rx="1" />
          <rect
            fillOpacity=".5"
            fill="#ED0874"
            x=".5"
            y="9.5"
            width="6"
            height="6"
            rx="1"
          />
          <rect fill="#FFF" x="10.5" y="9.5" width="6" height="6" rx="1" />
          <rect fill="#FFF" x=".5" y="18.5" width="6" height="6" rx="1" />
          <rect
            fillOpacity=".5"
            fill="#ED0874"
            x="10.5"
            y="18.5"
            width="6"
            height="6"
            rx="1"
          />
        </g>
        <g transform="translate(74 11)" fillRule="nonzero" stroke="#7D8D9E">
          <rect fill="#FFF" x=".5" y=".5" width="6" height="6" rx="1" />
          <rect
            fillOpacity=".5"
            fill="#ED0874"
            x="10.5"
            y=".5"
            width="6"
            height="6"
            rx="1"
          />
          <rect
            fillOpacity=".5"
            fill="#ED0874"
            x=".5"
            y="9.5"
            width="6"
            height="6"
            rx="1"
          />
          <rect fill="#FFF" x="10.5" y="9.5" width="6" height="6" rx="1" />
          <rect
            fillOpacity=".5"
            fill="#ED0874"
            x=".5"
            y="18.5"
            width="6"
            height="6"
            rx="1"
          />
          <rect fill="#FFF" x="10.5" y="18.5" width="6" height="6" rx="1" />
        </g>
        <rect
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          x="17.5"
          y="38.5"
          width="7"
          height="13"
          rx="1"
        />
        <rect
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          x="48.5"
          y="38.5"
          width="7"
          height="13"
          rx="1"
        />
        <rect
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          x="79.5"
          y="38.5"
          width="7"
          height="13"
          rx="1"
        />
        <circle fill="#7D8D9E" fillRule="nonzero" cx="19.5" cy="45.5" r="1" />
        <circle fill="#7D8D9E" fillRule="nonzero" cx="50.5" cy="45.5" r="1" />
        <circle fill="#7D8D9E" fillRule="nonzero" cx="81.5" cy="45.5" r="1" />
        <g transform="translate(0 51)">
          <rect
            fill="#7D8D9E"
            transform="matrix(-1 0 0 1 66 0)"
            width="66"
            height="1"
            rx=".5"
          />
          <rect
            fill="#7D8D9E"
            transform="matrix(-1 0 0 1 160 0)"
            x="68"
            width="24"
            height="1"
            rx=".5"
          />
          <rect
            fill="#7D8D9E"
            transform="matrix(-1 0 0 1 196 0)"
            x="93"
            width="10"
            height="1"
            rx=".5"
          />
          <rect
            stroke="#7D8D9E"
            x="20.5"
            y="3.5"
            width="28"
            height="1"
            rx=".5"
          />
          <rect
            stroke="#7D8D9E"
            x="50.5"
            y="3.5"
            width="46"
            height="1"
            rx=".5"
          />
          <rect stroke="#7D8D9E" x="5.5" y="3.5" width="3" height="1" rx=".5" />
          <rect
            stroke="#7D8D9E"
            x="10.5"
            y="3.5"
            width="7"
            height="1"
            rx=".5"
          />
        </g>
      </g>
    </svg>
  ) : (
    <svg width="103" height="62" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(0 7)" fill="none" fillRule="evenodd">
        <rect
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          x="26.5"
          y=".5"
          width="5"
          height="11"
          rx="1"
        />
        <rect
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          x="57.5"
          y=".5"
          width="5"
          height="11"
          rx="1"
        />
        <rect
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          x="88.5"
          y=".5"
          width="5"
          height="11"
          rx="1"
        />
        <path
          d="M7.723 10.12a.5.5 0 0 0-.223.415V51a.5.5 0 0 0 .5.5h25a.5.5 0 0 0 .5-.5V10.535a.5.5 0 0 0-.223-.416l-12.5-8.333a.5.5 0 0 0-.554 0l-12.5 8.333zM38.723 10.12a.5.5 0 0 0-.223.415V51a.5.5 0 0 0 .5.5h25a.5.5 0 0 0 .5-.5V10.535a.5.5 0 0 0-.223-.416l-12.5-8.333a.5.5 0 0 0-.554 0l-12.5 8.333zM69.723 10.12a.5.5 0 0 0-.223.415V51a.5.5 0 0 0 .5.5h25a.5.5 0 0 0 .5-.5V10.535a.5.5 0 0 0-.223-.416l-12.5-8.333a.5.5 0 0 0-.554 0l-12.5 8.333zM33.5 14.5h5v37h-5zM64.5 14.5h5v37h-5z"
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
        />
        <g
          transform="translate(12 11)"
          fill="#FFF"
          fillRule="nonzero"
          stroke="#7D8D9E"
        >
          <rect x=".5" y=".5" width="6" height="6" rx="1" />
          <rect x="10.5" y=".5" width="6" height="6" rx="1" />
          <rect x=".5" y="9.5" width="6" height="6" rx="1" />
          <rect x="10.5" y="9.5" width="6" height="6" rx="1" />
          <rect x=".5" y="18.5" width="6" height="6" rx="1" />
          <rect x="10.5" y="18.5" width="6" height="6" rx="1" />
        </g>
        <g
          transform="translate(43 11)"
          fill="#FFF"
          fillRule="nonzero"
          stroke="#7D8D9E"
        >
          <rect x=".5" y=".5" width="6" height="6" rx="1" />
          <rect x="10.5" y=".5" width="6" height="6" rx="1" />
          <rect x=".5" y="9.5" width="6" height="6" rx="1" />
          <rect x="10.5" y="9.5" width="6" height="6" rx="1" />
          <rect x=".5" y="18.5" width="6" height="6" rx="1" />
          <rect x="10.5" y="18.5" width="6" height="6" rx="1" />
        </g>
        <g
          transform="translate(74 11)"
          fill="#FFF"
          fillRule="nonzero"
          stroke="#7D8D9E"
        >
          <rect x=".5" y=".5" width="6" height="6" rx="1" />
          <rect x="10.5" y=".5" width="6" height="6" rx="1" />
          <rect x=".5" y="9.5" width="6" height="6" rx="1" />
          <rect x="10.5" y="9.5" width="6" height="6" rx="1" />
          <rect x=".5" y="18.5" width="6" height="6" rx="1" />
          <rect x="10.5" y="18.5" width="6" height="6" rx="1" />
        </g>
        <rect
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          x="17.5"
          y="38.5"
          width="7"
          height="13"
          rx="1"
        />
        <rect
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          x="48.5"
          y="38.5"
          width="7"
          height="13"
          rx="1"
        />
        <rect
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          x="79.5"
          y="38.5"
          width="7"
          height="13"
          rx="1"
        />
        <circle fill="#7D8D9E" fillRule="nonzero" cx="19.5" cy="45.5" r="1" />
        <circle fill="#7D8D9E" fillRule="nonzero" cx="50.5" cy="45.5" r="1" />
        <circle fill="#7D8D9E" fillRule="nonzero" cx="81.5" cy="45.5" r="1" />
        <rect
          fill="#7D8D9E"
          transform="matrix(-1 0 0 1 66 0)"
          y="51"
          width="66"
          height="1"
          rx=".5"
        />
        <rect
          fill="#7D8D9E"
          transform="matrix(-1 0 0 1 160 0)"
          x="68"
          y="51"
          width="24"
          height="1"
          rx=".5"
        />
        <rect
          fill="#7D8D9E"
          transform="matrix(-1 0 0 1 196 0)"
          x="93"
          y="51"
          width="10"
          height="1"
          rx=".5"
        />
        <rect
          stroke="#7D8D9E"
          x="20.5"
          y="54.5"
          width="28"
          height="1"
          rx=".5"
        />
        <rect
          stroke="#7D8D9E"
          x="50.5"
          y="54.5"
          width="46"
          height="1"
          rx=".5"
        />
        <rect stroke="#7D8D9E" x="5.5" y="54.5" width="3" height="1" rx=".5" />
        <rect stroke="#7D8D9E" x="10.5" y="54.5" width="7" height="1" rx=".5" />
      </g>
    </svg>
  )

Apartment.propTypes = {
  isChecked: PropTypes.bool,
}

export default Apartment

import styled from 'styled-components'
import { width, space } from 'styled-system'

import mq from 'styles/mq'

export const WhiteBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  background: white;
  box-shadow: 0 0 8px 0 rgba(2, 33, 53, 0.2);
  border: 1px solid #d5dee5;
  border-radius: 4px;

  ${space};
  ${width};
`

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 15px;
  ${mq.tablet`
    top: 15px;
  `};
`

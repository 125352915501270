import styled from 'styled-components'
import { space, textAlign } from 'styled-system'

const Label = styled.label`
  width: 100%;
  text-align: left;
  position: relative;
  display: block;
  ${space};
  ${textAlign};
`

export default Label

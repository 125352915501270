import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withProps } from 'recompose'
import { Formik } from 'formik'
import { Flex, Box } from 'grid-styled'
import { DateTime } from 'luxon'
import { range } from 'ramda'
import { Trans } from '@lingui/macro'

import Form from 'components/Form'
import ErrorMessage from 'components/Form/ErrorMessage'
import { Button } from 'components/buttons'
import Dropdown from 'components/Dropdown'
import { Text } from 'components/text'
import LocalizedDate from 'components/LocalizedDate'

import ModalBody from '../ModalBody'

const StyledDropdown = withProps({
  width: ['100%'],
  p: '15px 10px',
  arrowColor: '#9DB1C6',
  fontWeight: 400,
})(Dropdown)

class ContractEndDate extends Component {
  constructor(props) {
    super(props)
    const nextMonth = DateTime.local().plus({ months: 1 })
    const defaultMonth = nextMonth.month
    const defaultYear = nextMonth.year

    this.monthList = range(1, 13).map(month => ({
      label: (
        <LocalizedDate
          date={DateTime.local(defaultYear, month).toFormat('yyyy-LL')}
          format="LLLL"
        />
      ),
      value: month.toString(),
    }))
    this.yearList = range(defaultYear, defaultYear + 5).map(year => ({
      label: year.toString(),
      value: year.toString(),
    }))

    const { contractEndDate } = props
    const date = contractEndDate ? DateTime.fromISO(contractEndDate) : null

    this.defaultMonth = date ? date.month.toString() : defaultMonth.toString()

    this.defaultYear = date ? date.year.toString() : defaultYear.toString()
  }

  validate = values => {
    const errors = {}
    const { endMonth, endYear } = values
    const date = DateTime.local(Number(endYear), Number(endMonth))

    if (date.diffNow('months').values.months < 0) {
      errors.message = (
        <Trans>
          We can do a lot of things at Griddy, but time travel isn’t one of
          them. Please enter a contract end date that hasn’t passed.
        </Trans>
      )
    }
    return errors
  }

  render() {
    const { onSubmit, onClose, formStatus } = this.props
    return (
      <ModalBody
        dataTestId="contractEndDateModal"
        formStatus={formStatus}
        title={<Trans>We Hate Contracts Too</Trans>}
        subTitle={
          <Trans>
            When the contract with your current provider ends, we'll send you a
            reminder to go Griddy.
          </Trans>
        }
        alignLeft
        onClose={onClose}
      >
        <Formik
          initialValues={{
            endMonth: this.defaultMonth,
            endYear: this.defaultYear,
          }}
          validate={this.validate}
          onSubmit={onSubmit}
          render={({
            isSubmitting,
            isValid,
            values,
            errors,
            setFieldValue,
          }) => (
            <Form
              isSubmitting={isSubmitting}
              isValid={isValid}
              width={['100%']}
              mt={['20px', '30px']}
            >
              <Flex
                style={{ position: 'relative' }}
                justifyContent="space-between"
              >
                <Box flex="1" pr={['8px', '10px']} width={['100%', '250px']}>
                  <Text medium textAlign="left" ml={'5px'}>
                    <Trans>End Month</Trans>
                  </Text>
                  <StyledDropdown
                    dataTestId="selectEndMonthDropdown"
                    label={
                      this.monthList.find(
                        month => month.value === values.endMonth
                      ).label
                    }
                    selected={values.endMonth}
                    items={this.monthList}
                    onSelect={value => {
                      setFieldValue('endMonth', value)
                    }}
                  />
                </Box>
                <Box flex="1" pl={['8px', '10px']} width={['100%', '250px']}>
                  <Text medium textAlign="left" ml={'5px'}>
                    <Trans>End Year</Trans>
                  </Text>
                  <StyledDropdown
                    dataTestId="selectEndYearDropdown"
                    label={values.endYear}
                    selected={values.endYear}
                    items={this.yearList}
                    onSelect={value => {
                      setFieldValue('endYear', value)
                    }}
                  />
                </Box>
              </Flex>
              {errors.message && <ErrorMessage>{errors.message}</ErrorMessage>}
              <Button
                data-testid="submitButton"
                loading={isSubmitting}
                width={['140px', '154px']}
                mt={['20px', '30px']}
              >
                <Trans>Remind Me</Trans>
              </Button>
            </Form>
          )}
        />
      </ModalBody>
    )
  }
}

ContractEndDate.propTypes = {
  formStatus: PropTypes.object.isRequired,
  contractEndDate: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ContractEndDate

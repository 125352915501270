import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'

import { selectGuestZipCode } from 'modules/members/selectors'

export const GuestRoute = ({ hasZipCode, ...rest }) =>
  hasZipCode ? <Route {...rest} /> : <Redirect to="/" />

GuestRoute.propTypes = {
  hasZipCode: PropTypes.string,
}

const mapStateToProps = createStructuredSelector({
  hasZipCode: selectGuestZipCode,
})

export default connect(mapStateToProps)(GuestRoute)

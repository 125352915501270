import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import { compose, withState, withProps } from 'recompose'

import WholesalePage from 'pages/Wholesale/Loadable'
import UsagePage from 'pages/Usage/Loadable'
import StatementPage from 'pages/Statement/Loadable'
import GuestAccountPage from 'pages/Account/LoadableGuest'
import SupportPage from 'pages/Support/Loadable'
import SavingsPage from 'pages/Savings/Loadable'

import GuestRoute from 'components/Routes/GuestRoute'

import { MainContainer } from '../styled'
import Banner from './Banner'
import ModalForm from './ModalForm'
import { formTypes } from './ModalForm/constants'

const GuestDashboard = ({ modalFormType, setModalFormType, location }) => (
  <>
    {modalFormType && (
      <ModalForm
        location={location}
        modalFormType={modalFormType}
        onClose={() => setModalFormType(null)}
      />
    )}
    <MainContainer>
      <Banner />
      <Switch>
        <Route exact path="/" component={WholesalePage} />
        <GuestRoute exact path="/usage" component={UsagePage} />
        <GuestRoute exact path="/usage_v2" component={UsagePage} />
        <GuestRoute exact path="/statement" component={StatementPage} />
        <GuestRoute exact path="/savings" component={SavingsPage} />
        <GuestRoute
          path="/account"
          render={({ match }) => (
            <GuestAccountPage
              match={match}
              setModalFormType={setModalFormType}
            />
          )}
        />
        <GuestRoute path="/help" component={SupportPage} />
        <Redirect to="/" />
      </Switch>
    </MainContainer>
  </>
)

GuestDashboard.propTypes = {
  location: PropTypes.object.isRequired,
  hasZipCode: PropTypes.bool.isRequired,
  hasHomeSize: PropTypes.bool.isRequired,
  modalFormType: PropTypes.string,
  setModalFormType: PropTypes.func.isRequired,
}

export default compose(
  withState('modalFormType', 'setModalFormType', null),
  withProps(props => {
    const { location, hasZipCode, hasHomeSize, modalFormType } = props
    if (!hasZipCode && location.pathname === '/') {
      return {
        modalFormType: formTypes.WELCOME,
      }
    }

    const blockedPathnames = /^\/(usage|statement|savings)/
    const forceHomeSizeForm =
      !hasHomeSize && blockedPathnames.test(location.pathname)
    if (forceHomeSizeForm) {
      return {
        modalFormType: formTypes.SET_HOME_SIZE,
      }
    }

    return {
      modalFormType,
    }
  })
)(GuestDashboard)

import React from 'react'
import styled, { css } from 'styled-components'
import ReactTextMask from 'react-text-mask'

import { input, font } from 'styles/variables'

const getBorderColor = props =>
  props.hasError ? input.borderColorError : input.borderColor

export const Input = styled.input`
  font-family: ${font.baseFamily};
  .fontLoaded & {
    font-family: ${font.customFamily};
  }

  font-weight: ${font.normal};
  width: 100%;
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
  height: 48px;
  padding: 0 10px;
  border-radius: 4px;
  font-size: 1.8rem;
  color: ${input.text};
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
  outline: none;
  transition: 0.2s border-color;
  border: 1px solid ${props => getBorderColor(props)};
  margin-top: ${props => (props.inputMt ? props.inputMt : '0')};
  letter-spacing: 0.05em;

  :-webkit-autofill {
    /* stylelint-disable-next-line */
    -webkit-box-shadow: 0 0 0 30px white inset;
  }

  &:focus {
    border-color: ${input.borderColorFocused};
  }

  ${props => props.focused && `border-color: ${input.borderColorFocused}`};

  ${props =>
    props.isStripeElement &&
    css`
      display: flex;
      > div {
        width: 100%;
        align-self: center;
      }
    `};
`
// eslint-disable-next-line
export const InputMask = Input.withComponent(({ hasError, ...rest }) => (
  <ReactTextMask {...rest} />
))

export const Text = styled.span`
  font-size: 1.6rem;
  font-weight: ${font.medium};
  margin: 0 0 5px 5px;
`

export const Error = styled.div`
  color: red;
  margin-top: 0.2em;
`

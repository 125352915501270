import { all, takeLatest, call, put, select } from 'redux-saga/effects'

import * as API from 'api'
import {
  selectCurrentMeterUID,
  selectCurrentMemberID,
} from 'modules/members/selectors'

import { selectMeterID, selectMemberID } from './selectors'

import * as C from './constants'
import {
  // getPriceLockRateSuccess,
  // getPriceLockRateFailure,
  setPriceLockRequestSuccess,
  setPriceLockRequestFailure,
  updateAutoSummerLockSuccess,
  updateAutoSummerLockFailure,
  setIDs,
} from './actions'

import { getMeterSaga } from '../../containers/App/saga'

function* getPriceLockRateSaga(action) {
  const { payload: data } = action
  const meterID = data ? data.meterID : yield select(selectCurrentMeterUID)
  const memberID = data ? data.memberID : yield select(selectCurrentMemberID)

  yield put(setIDs({ meterID, memberID }))

  // tempRelease: disabling fetching the price data for temp release
  // try {
  //   yield call(getMeterSaga, { memberID })
  //   const response = yield call(API.getPriceLockRate, { meterID })
  //   if (response.error) {
  //     yield put(
  //       getPriceLockRateFailure(
  //         'Failed to get Lock price rate, Please try again later.'
  //       )
  //     )
  //   } else {
  //     yield put(getPriceLockRateSuccess(response))
  //   }
  // } catch (error) {
  //   yield put(
  //     getPriceLockRateFailure(
  //       'Failed to get Lock price rate, Please try again later.'
  //     )
  //   )
  // }
}

function* setPriceLockSaga({ payload }) {
  const { lockPrice } = payload
  const lockStatus = lockPrice ? 'locked' : 'unlocked'
  const meterID = yield select(selectMeterID)
  const memberID = yield select(selectMemberID)

  try {
    const response = yield call(API.setPriceLock, {
      meterID,
      set_lock_status: lockStatus,
    })
    if (response.success) {
      yield put(setPriceLockRequestSuccess(response))
      yield call(getMeterSaga, { memberID })
    } else {
      yield put(setPriceLockRequestFailure(response.message))
    }
  } catch (error) {
    yield put(setPriceLockRequestFailure(error))
  }
}

function* updateAutoSummerLockSaga({ payload }) {
  const { summerLock } = payload
  const meterID = yield select(selectMeterID)

  try {
    const response = yield call(API.updateSummerAutoLock, {
      meterID,
      summer_auto_lock: summerLock,
    })

    if (response.success) {
      yield put(updateAutoSummerLockSuccess(summerLock))
    } else {
      yield put(
        updateAutoSummerLockFailure(
          'Failed to update auto summer lock, Please try again later'
        )
      )
    }
  } catch (error) {
    yield put(updateAutoSummerLockFailure(error))
  }
}

export default function* defaultSaga() {
  yield all([
    takeLatest(C.GET_PRICE_LOCK_RATE, getPriceLockRateSaga),
    takeLatest(C.SET_PRICE_LOCK_REQUEST, setPriceLockSaga),
    takeLatest(C.UPDATE_AUTO_SUMMER_LOCK, updateAutoSummerLockSaga),
  ])
}

import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'

import UsagePage from 'pages/Usage/Loadable'
import StatementPage from 'pages/Statement/Loadable'
import AccountPage from 'pages/Account/Loadable'
import SupportPage from 'pages/Support/Loadable'
import PriceLock from 'pages/PriceLock'
import WholesalePage from 'pages/Wholesale/Loadable'
import RenewalPage from 'pages/Renewal/Loadable'

import { MainContainer } from '../styled'

import SwitchMeterModal from './SwitchMeterModal'
import { routes } from 'config/profile'

const FullDashboard = ({ switchMeter, isWebView }) => {
  return (
    <Switch>
      <Route
        render={() => (
          <MainContainer isWebView={isWebView}>
            <Switch>
              <Route exact path="/">
                <Redirect to={routes.homePagePath} />
              </Route>
              {routes.wholesale && (
                <Route exact path="/wholesale" component={WholesalePage} />
              )}
              <Route exact path="/usage" component={UsagePage} />
              <Route exact path="/usage_v2" component={UsagePage} />
              <Route exact path="/statement" component={StatementPage} />
              <Route exact path="/price-lock" component={PriceLock} />
              <Route path="/account" component={AccountPage} />
              <Route path="/renewal" component={RenewalPage} />
              <Route path="/help" component={SupportPage} />
              <Redirect to="/" />
            </Switch>
            {switchMeter && <SwitchMeterModal switchMeter={switchMeter} />}
          </MainContainer>
        )}
      />
    </Switch>
  )
}

FullDashboard.propTypes = {
  switchMeter: PropTypes.object,
  isWebView: PropTypes.bool,
}

export default FullDashboard

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withOutsideClick from 'react-onclickoutside'
import scrollIntoView from 'scroll-into-view-if-needed'
import { Trans } from '@lingui/macro'

import Arrow from 'assets/images/icons/ArrowBottom'
import { Wrapper, Button, List, ListItem, ListButton } from './styled'

/* eslint-disable react/no-array-index-key */
class Dropdown extends Component {
  state = { isOpen: false }

  handleClickOutside = () => {
    if (this.state.isOpen) {
      this.toggle()
    }
  }

  toggle = () => this.setState(({ isOpen }) => ({ isOpen: !isOpen }))

  render() {
    const {
      dataTestId,
      label,
      selected,
      items,
      onSelect,
      width,
      p = '10px 15px',
      m = '0px',
      arrowColor = '#181616',
      fontWeight = 600,
    } = this.props
    const { isOpen } = this.state
    return (
      <Wrapper width={width} m={m}>
        <Button
          type="button"
          data-testid={dataTestId}
          onClick={this.toggle}
          isOpen={isOpen}
          p={p}
          fontWeight={fontWeight}
        >
          {typeof label === 'string' ? <Trans id={label} /> : label}
          <Arrow color={arrowColor} />
        </Button>
        {isOpen && (
          <List data-testid="dropdownList">
            {items.map((item, index) => (
              <ListItem key={index} isActive={selected === item.value}>
                <ListButton
                  data-testid={`dropdownItem:${item.label.props}`}
                  innerRef={ref =>
                    ref &&
                    selected === item.value &&
                    scrollIntoView(ref, { offset: { bottom: 100 } })
                  }
                  type="button"
                  onClick={() => {
                    onSelect(item.value)
                    this.toggle()
                  }}
                >
                  {typeof item.label === 'string' ? (
                    <Trans id={item.label} />
                  ) : (
                    item.label
                  )}
                </ListButton>
              </ListItem>
            ))}
          </List>
        )}
      </Wrapper>
    )
  }
}

Dropdown.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  selected: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  width: PropTypes.array,
  p: PropTypes.string,
  arrowColor: PropTypes.string,
  fontWeight: PropTypes.number,
}

export default withOutsideClick(Dropdown)

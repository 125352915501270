import React from 'react'
import PropTypes from 'prop-types'
import { withStateHandlers } from 'recompose'
import { Field as FormikField } from 'formik'

import { omit } from 'ramda'

import Label from 'components/Form/Label'
import ErrorMessage from 'components/Form/ErrorMessage'
import ErrorPassword from 'components/Form/ErrorPassword'

import { Input, InputMask, Text } from './styled'

const Field = props => {
  const {
    label,
    isFocused,
    newPassword,
    name,
    mb = 20,
    errorPositionAbsolute,
    ...rest
  } = props
  const Component = !rest.mask ? Input : InputMask

  return (
    <FormikField
      name={name}
      render={({
        field: { onChange, ...field },
        form: { touched, errors, status = {}, setStatus },
      }) => {
        const isTouched = touched[name]
        const error = status[name] || errors[name]

        let Error = null
        if (newPassword && (isFocused || isTouched))
          Error = <ErrorPassword isTouched={isTouched} password={field.value} />
        else if (isTouched && error)
          Error = (
            <ErrorMessage
              position={errorPositionAbsolute ? 'absolute' : 'inherit'}
              m={errorPositionAbsolute ? '5px 0 0 0' : '5px 0 0 5px'}
              errorField={name}
            >
              {error}
            </ErrorMessage>
          )

        return (
          <Label mb={mb}>
            <Text>{label}</Text>
            <Component
              hasError={isTouched && error}
              onChange={event => {
                onChange(event)
                if (status[name]) {
                  setStatus(omit([name], status))
                }
              }}
              {...field}
              {...rest}
            />
            {Error}
          </Label>
        )
      }}
    />
  )
}

Field.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  isFocused: PropTypes.bool.isRequired,
  newPassword: PropTypes.bool,
  errorPositionAbsolute: PropTypes.bool,
  mb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

Field.defaultProps = {
  newPassword: false,
}

export default withStateHandlers(
  { isFocused: false },
  { onFocus: () => () => ({ isFocused: true }) }
)(Field)

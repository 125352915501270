import React from 'react'

const Account = () => (
  <svg width="26" height="26" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h26v26H0z" />
      <path
        d="M5.484 21.667h15.032a8.704 8.704 0 0 0-3.116-5.662l1.372-1.677a10.873 10.873 0 0 1 3.924 7.339h.054v1.083c0 .598-.485 1.083-1.083 1.083H4.333A1.083 1.083 0 0 1 3.25 22.75v-1.083h.054a10.84 10.84 0 0 1 3.947-7.34l1.368 1.68a8.674 8.674 0 0 0-3.135 5.66zm7.516-6.5a6.5 6.5 0 1 1 0-13 6.5 6.5 0 0 1 0 13zM13 13a4.333 4.333 0 1 0 0-8.667A4.333 4.333 0 0 0 13 13z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default Account

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Flex } from 'grid-styled'
import { Trans } from '@lingui/macro'
import { compose, lifecycle, withStateHandlers } from 'recompose'
// import { LoopCircleLoading } from 'react-loadingg'
import { parse } from 'query-string'

import { Text } from 'components/text'
import {
  selectIsPriceLocked,
  selectPriceLockRatePrice,
  selectPriceLockRateEndDate,
  selectEligibleUnlockDate,
  selectSummerAutoLock,
  selectLoading,
  selectErrorMessage,
  selectEfl,
} from './selectors'
import { selectWebView } from 'modules/app/selectors'
import {
  setPriceLockRequest,
  getPriceLockRate,
  updateAutoSummerLock,
} from './actions'
import priceLockSaga from './saga'
import injectSaga from 'utils/injectSaga'
import { DateTime } from 'luxon'
import Modal from 'components/Modal'

import {
  Container,
  LockContainer,
  PlanBannerContainer,
  LockButton,
  StyledCheckbox,
  Divider,
  AutoSummerLockContainer,
  CheckboxContainer,
  ErrorContainer,
  Button,
  A,
  PriceLockComingSoon,
  LearnMoreButton,
} from './styled'

const PriceLock = ({
  priceLocked,
  updatePriceLock,
  updateAutoSummerLock,
  lockPriceRate,
  lockPriceEndDate,
  eligibleUnlockDate,
  summerAutoLock,
  toggleModal,
  isModalOpen,
  loading,
  errorMessage,
  efl,
  isAutoSummerModalOpen,
  toggleAutoSummerModal,
}) => {
  const now = new Date()
  const eDate = new Date(eligibleUnlockDate)
  const eligibleToUnlock = eDate < now

  const formattedLockPriceEndDate = DateTime.fromISO(lockPriceEndDate).toFormat(
    ' LL/dd/yyyy'
  )

  const formattedCalcEligibleUnlockDate = DateTime.fromISO(lockPriceEndDate)
    .plus({
      days: 1,
    })
    .toFormat(' LL/dd/yyyy')

  const formattedEligibleUnlcockDate = DateTime.fromISO(
    eligibleUnlockDate
  ).toFormat(' LL/dd/yyyy')
  return (
    <Container>
      {/* tempRelease: disabling the loading for now */}
      {/* {loading && <LoopCircleLoading color="#ed0874" size="large" />} */}

      {/* tempRelease: display: none for PlanBannerContainer -> change back to flex */}
      <PlanBannerContainer>
        <Flex justifyContent="center" alignItems="center">
          <Text fontSize="14px" color="neutral2" mr={12}>
            Current Rate:{' '}
            {priceLocked ? 'Griddy Price Lock' : 'Griddy Wholesale'}
          </Text>
        </Flex>
      </PlanBannerContainer>

      {errorMessage && (
        <ErrorContainer>
          <Text color="#fff">{errorMessage}</Text>
        </ErrorContainer>
      )}
      <Text textAlign="center" fontSize="28px" mt={20}>
        Griddy Price Protection
      </Text>
      <Text textAlign="center" fontSize="18px" color="neutral2">
        Coming March 1
      </Text>

      <Flex justifyContent="center" mt={15}>
        <A
          href="https://www.griddy.com/post/wholesale-with-price-protection-coming-soon"
          target="_blank"
          rel="noopener noreferrer"
          textColor="primary"
        >
          <LearnMoreButton>Learn More</LearnMoreButton>
        </A>
      </Flex>

      <PriceLockComingSoon />
      {/* tempRelease: display: none for LockContainer -> change back to flex */}
      {!loading && (
        <LockContainer>
          <Text fontSize="22px" mt={25}>
            {priceLocked ? 'Your Rate is Locked' : 'Lock This Rate'}
          </Text>
          <Flex alignItems="baseline">
            <Text fontSize="104px" color="blue" mt={-10}>
              {lockPriceRate}
            </Text>
            <Text fontSize="56px" color="blue">
              ¢
            </Text>
          </Flex>

          <Flex mt={-35} flexDirection="column">
            <Text fontSize="12px" color="neutral2" textAlign="center">
              /kWh
            </Text>
            {/* TODO update link */}
            <Flex mt={10} alignItems="flex-end">
              <Text fontSize="12px" color="neutral2" textAlign="center">
                Includes TDSP charges and ancillary services. <br />
                Based on 2,000 kWh a monthly usage.{' '}
                <A
                  href="https://www.griddy.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  textColor="primary"
                >
                  Learn More
                </A>
              </Text>
            </Flex>
          </Flex>

          <LockButton
            locked={priceLocked}
            eligibleToUnlock={eligibleToUnlock}
            onClick={() => {
              if (!(priceLocked && !eligibleToUnlock)) {
                toggleModal()
              }
            }}
          >
            {priceLocked && !eligibleToUnlock && (
              <Flex flexDirection="column">
                <Text large medium color="white">
                  Locked unitil{' '}
                  {DateTime.fromISO(eligibleUnlockDate.split('T')[0]).toFormat(
                    ' LL/dd/yyyy'
                  )}
                </Text>
              </Flex>
            )}

            {!priceLocked && (
              <Text large medium color="white">
                Lock This Rate
              </Text>
            )}

            {priceLocked && eligibleToUnlock && (
              <Text large medium color="white">
                Unlock To Wholesale Rates
              </Text>
            )}
          </LockButton>

          {priceLocked && eligibleToUnlock ? (
            <Text color="primary" fontSize="14px" mt={15}>
              <a
                href="https://www.griddy.com/legal"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Wholesale EFL
              </a>
            </Text>
          ) : (
            <Text color="primary" fontSize="14px" mt={20}>
              <a href={efl} target="_blank" rel="noopener noreferrer">
                View Energy Facts Labels and Terms of Service
              </a>
            </Text>
          )}

          <Text small mt={20}>
            {!priceLocked &&
              `Lock this rate from now until ${formattedLockPriceEndDate}. You will be eligible to unlock on
                 ${formattedCalcEligibleUnlockDate}
     `}
            {priceLocked &&
              !eligibleToUnlock &&
              `On ${formattedEligibleUnlcockDate}, we will email you the locked rate for next 3 months, until then enjoy your no fuss, no muss electricity rates.`}

            {priceLocked &&
              eligibleToUnlock &&
              `Want to get back on the wholesale train? Click the button above to take advantage of low, wholesale prices. You'll be placed on the wholesale plan right away.
                 `}
          </Text>
        </LockContainer>
      )}

      {/* tempRelease: display: none for Divider -> remove display */}
      <Divider />
      <AutoSummerLockContainer>
        <Text fontSize="22px" textAlign="center">
          Summer Price Protection
        </Text>

        <Text fontSize="16px" mt={10}>
          We'll automatically switch you to our set rates on June 1.
        </Text>

        <CheckboxContainer>
          <StyledCheckbox
            isChecked={summerAutoLock}
            onClick={() => {
              toggleAutoSummerModal()
            }}
          />

          <Text fontSize="14px">Opt in to summer Price Protection</Text>
        </CheckboxContainer>
      </AutoSummerLockContainer>

      {isModalOpen && (
        <Modal
          title={
            priceLocked ? (
              <Trans>Return to Wholesale</Trans>
            ) : (
              <Trans>Lock This Rate</Trans>
            )
          }
          onClose={toggleModal}
        >
          {priceLocked ? (
            <Text fontSize="13px" mb={20}>
              By clicking on "Go To Wholesale Pricing" you will return back to
              wholesale rates.
            </Text>
          ) : (
            <Text fontSize="13px" mb={20}>
              By clicking on "Lock This Rate,” you will be locked into the rate
              of {lockPriceRate}¢ /kWh from now until{' '}
              {formattedLockPriceEndDate}. You will be eligible to unlock on{' '}
              {formattedCalcEligibleUnlockDate}.
            </Text>
          )}

          {priceLocked ? (
            <Button
              onClick={() => {
                updatePriceLock({ lockPrice: false })
                toggleModal()
              }}
              mr={10}
              main
            >
              <Trans>Go To Wholesale Pricing</Trans>
            </Button>
          ) : (
            <Button
              onClick={() => {
                updatePriceLock({ lockPrice: true })
                toggleModal()
              }}
              mr={10}
              main
            >
              <Trans>Lock This Rate</Trans>
            </Button>
          )}

          <Button onClick={toggleModal} secondary>
            <Trans>Not Now</Trans>
          </Button>
        </Modal>
      )}

      {isAutoSummerModalOpen && (
        <Modal
          title={
            summerAutoLock ? (
              <Trans>Opt out Summer Auto Lock</Trans>
            ) : (
              <Trans>Opt in Summer Auto Lock</Trans>
            )
          }
          onClose={toggleAutoSummerModal}
        >
          {summerAutoLock ? (
            <Text fontSize="13px" mb={20}>
              Opt out of Summer Price Lock for now. You can opt back in at any
              time before June 1st. After June 1st, just click on "Lock This
              Rate" to lock your rate for the remainder of the summer.
            </Text>
          ) : (
            <Text fontSize="13px" mb={20}>
              When you opt in to Summer Price Lock we will automatically lock
              your rate for the summer period, starting on June 1. You can opt
              out of the Summer Price Lock at any time before June 1st. After
              your price is locked, you will be eligible to unlock to wholesale
              on September 1.
            </Text>
          )}

          <Button
            onClick={() => {
              updateAutoSummerLock({ summerLock: !summerAutoLock })
              toggleAutoSummerModal()
            }}
            mr={10}
            main
          >
            {summerAutoLock ? (
              <Trans>Yes, Opt out</Trans>
            ) : (
              <Trans>Yes, Opt in</Trans>
            )}
          </Button>

          <Button onClick={toggleAutoSummerModal} secondary>
            <Trans>Not Now</Trans>
          </Button>
        </Modal>
      )}
    </Container>
  )
}
PriceLock.propTypes = {
  priceLocked: PropTypes.bool.isRequired,
  updatePriceLock: PropTypes.func.isRequired,
  updateAutoSummerLock: PropTypes.func.isRequired,
  lockPriceRate: PropTypes.number,
  lockPriceEndDate: PropTypes.string,
  eligibleUnlockDate: PropTypes.string,
  summerAutoLock: PropTypes.bool,
  isModalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  efl: PropTypes.string,
  isAutoSummerModalOpen: PropTypes.bool.isRequired,
  toggleAutoSummerModal: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({
  priceLocked: selectIsPriceLocked,
  lockPriceRate: selectPriceLockRatePrice,
  lockPriceEndDate: selectPriceLockRateEndDate,
  eligibleUnlockDate: selectEligibleUnlockDate,
  summerAutoLock: selectSummerAutoLock,
  loading: selectLoading,
  errorMessage: selectErrorMessage,
  efl: selectEfl,
  webView: selectWebView,
})

const mapDispatchToProps = {
  updatePriceLock: setPriceLockRequest,
  getPriceLockRate,
  updateAutoSummerLock,
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),

  lifecycle({
    componentDidMount() {
      const {
        location: { search },
        webView,
      } = this.props
      const { meterID, memberID } = parse(search)
      const data = webView ? { meterID, memberID } : null
      this.props.getPriceLockRate(data)
    },
  }),

  withStateHandlers(
    { isModalOpen: false, isAutoSummerModalOpen: false },
    {
      toggleModal: ({ isModalOpen }) => () => ({ isModalOpen: !isModalOpen }),
      toggleAutoSummerModal: ({ isAutoSummerModalOpen }) => () => ({
        isAutoSummerModalOpen: !isAutoSummerModalOpen,
      }),
    }
  ),

  injectSaga({ key: 'pricelock', saga: priceLockSaga })
)(PriceLock)

import React from 'react'
import styled from 'styled-components'

import { Button } from 'components/buttons'
import { breakpoints } from 'styles/mq'

// eslint-disable-next-line no-unused-vars
const SubmitButton = styled(({ fullWidth, ...rest }) => (
  <Button {...rest} data-testid="submitButton" />
))`
  min-width: ${props => (props.minWidth ? props.minWidth : '0')};

  @media (max-width: ${breakpoints.tablet - 1}px) {
    text-align: center;
    position: fixed;
    z-index: 10;
    bottom: 0;
    right: 0;
    border-radius: 0;
    height: 60px;
    line-height: 60px;
    margin: 0;
    padding: 0;
    width: ${props => (props.fullWidth ? '100%' : '120px')};
  }
`

export default SubmitButton

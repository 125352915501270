import React from 'react'
import { SVG } from './styled'

const Invalid = () => (
  <SVG
    width="10"
    height="10"
    viewBox="0 0 10 10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="#DD2C00"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <path d="M9 1L1 9M1 1l8 8" />
    </g>
  </SVG>
)

export default Invalid

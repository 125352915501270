import React from 'react'
import { createSelector } from 'reselect'
import { find, propEq, pathOr, pathEq } from 'ramda'
import { Trans } from '@lingui/macro'
import idx from 'idx.macro'
import { companyInfo } from 'config/profile'

const selectMembers = state => state.members.filter(x => !x.archive)

export const selectFirstMember = createSelector(
  selectMembers,
  members => members.find(member => member.meter) || {}
)

export const selectEnrolledMembers = createSelector(selectMembers, members =>
  members.filter(member => member.meter)
)

export const selectFirstAbandonedMember = createSelector(
  selectMembers,
  members => members.filter(member => !member.meter)[0]
)

export const makeSelectMemberByID = memberID =>
  createSelector(selectMembers, members =>
    find(propEq('memberID', memberID))(members)
  )

export const selectCurrentMeterName = createSelector(
  selectEnrolledMembers,
  members => {
    if (members.length < 2) {
      return null
    }

    let selectedIndex = null
    members.some((member, index) => {
      if (member.isSelected) {
        selectedIndex = index
      }
      return member.isSelected
    })

    return selectedIndex === null ? (
      selectedIndex
    ) : (
      <Trans>Meter {selectedIndex + 1}</Trans>
    )
  }
)

export const selectCurrentMember = createSelector(
  selectMembers,
  members =>
    find(propEq('isGriddyGuest', true))(members) ||
    find(propEq('isSelected', true))(members) ||
    {}
)

export const selectCurrentMemberID = createSelector(
  selectCurrentMember,
  member => member.memberID
)

export const selectRechargeAmount = createSelector(
  selectCurrentMember,
  member => member.recharge_dollars
)

export const selectMemberCreatedDate = createSelector(
  selectCurrentMember,
  member => member.created_date
)

export const selectMemberAddress = createSelector(
  selectCurrentMember,
  member => member.address
)

export const selectIsGriddyGuest = createSelector(
  selectCurrentMember,
  member => member.isGriddyGuest || false
)

export const selectGuestZipCode = createSelector(
  selectCurrentMember,
  member => member.isGriddyGuest && member.zip_code
)

export const selectGuestHomeSize = createSelector(
  selectCurrentMember,
  member => member.isGriddyGuest && member.home_size
)

export const selectGuestContractEndDate = createSelector(
  selectCurrentMember,
  member => member.isGriddyGuest && member.contract_end_date
)

export const selectCurrentMeter = createSelector(
  selectCurrentMember,
  member => member.meter || {}
)

export const selectCurrentMeterID = createSelector(
  selectCurrentMeter,
  meter => meter.meterIdentifier
)

export const selectCurrentMeterUID = createSelector(
  selectCurrentMember,
  selectCurrentMeter,
  (member, meter) => (member.isGriddyGuest ? member.profileID || '0' : meter.ID)
)

export const selectServiceAddress = createSelector(
  selectCurrentMeter,
  meter => meter.service_address
)

export const selectSettlementPoint = createSelector(
  selectCurrentMember,
  selectCurrentMeter,
  (member, meter) =>
    member.isGriddyGuest ? member.settlement_point : meter.settlement_point
)

export const selectMeterStatus = createSelector(selectCurrentMeter, meter =>
  idx(meter, _ => _.status.status)
)

export const selectLoadZone = createSelector(
  selectCurrentMeter,
  meter => meter.settlement_point
)

export const selectTimeZone = createSelector(
  selectCurrentMeter,
  selectIsGriddyGuest,
  (meter, isGriddyGuest) => {
    const defaultTimeZone = isGriddyGuest
      ? 'America/Chicago'
      : companyInfo.defaultTimeZone || 'local'
    // If time zone is an empty string then set to null so returning statement executes
    if (pathEq(['service_address', 'time_zone_name'], '', meter)) {
      meter.service_address.time_zone_name = null
    }
    return pathOr(defaultTimeZone, ['service_address', 'time_zone_name'], meter)
  }
)

export const selectIsResidential = createSelector(
  selectCurrentMeter,
  meter => meter.griddy_rate_class === 'residential'
)

export const selectMembershipRate = createSelector(
  selectCurrentMeter,
  meter => meter.membership_rate
)

export const selectTierName = createSelector(
  selectIsGriddyGuest,
  selectIsResidential,
  selectCurrentMeter,
  (isGriddyGuest, isResidential, meter) => {
    if (isGriddyGuest) {
      return 'residential'
    }

    if (!meter.tier_name) {
      return ''
    }

    if (isResidential) {
      return meter.tier_name
    }
    const [, number] = meter.tier_name.split(' ')
    return `Tier ${number < 5 ? number : 5}`
  }
)

export const selectIsTierPending = createSelector(
  selectCurrentMeter,
  meter => meter.tier_pending
)

export const selectMeterEnrollmentDate = createSelector(
  selectCurrentMeter,
  meter => meter.enrollment_date
)

export const selectMeterStartDate = createSelector(
  selectCurrentMeter,
  meter => meter.start_date
)

export const selectMeterEndDate = createSelector(
  selectCurrentMeter,
  meter => meter.end_date
)

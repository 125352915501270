import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'grid-styled'
import styled from 'styled-components'

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${props => (props.isActive ? '#D5DEE5' : '#F2F3F6')};

  margin-right: 3px;
  &:last-of-type {
    margin: 0;
  }
`

const Pagination = ({ activeIndex }) => (
  <Flex justifyContent="center" mt="16px">
    {[0, 1, 2].map(value => (
      <Dot key={value} isActive={activeIndex === value} />
    ))}
  </Flex>
)

Pagination.propTypes = {
  activeIndex: PropTypes.number.isRequired,
}

export default Pagination

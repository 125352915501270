import React from 'react'
import { Trans } from '@lingui/macro'

import ErrorTaken from 'components/Form/ErrorTaken'

export const errors = {
  'no user found': {
    message: (
      <Trans>
        We do not have an account associated with that email address. Please try
        another one.
      </Trans>
    ),
    field: 'email',
  },
  'could not verify password': {
    message: (
      <Trans>
        The email and password you entered do not match. Please try again.
      </Trans>
    ),
    field: 'password',
  },
  'failed to validate request. error: agreed must be accepted': {
    message: (
      <Trans>
        Please agree to the terms of the member agreement before continuing
      </Trans>
    ),
    field: 'agreed',
  },
  'user already exists with your phone or email': {
    message: <ErrorTaken />,
    field: 'email',
  },
  'failed to validate request. error: Email is not in valid format': {
    message: <Trans>Email is not in valid format</Trans>,
    field: 'email',
  },
  'failed to validate request. error: password is not in valid format (must have three of the following: upper case, lower case, number, special character)': {
    message: (
      <Trans>
        Password is not in valid format (must have three of the following: upper
        case, lower case, number, special character)
      </Trans>
    ),
    field: 'password',
  },
  'could not verify old password': {
    message: <Trans>Please enter your current password</Trans>,
    field: 'password',
  },
  'new phone number is not in valid format': {
    message: <Trans>Please enter a valid mobile phone number</Trans>,
    field: 'phone',
  },
  'Internal Server Error': {
    message: <Trans>Something went wrong. Please try again later.</Trans>,
  },
  'No user was found for provided email or phone number': {
    message: (
      <Trans>
        We do not have an account associated with that email address. Please try
        another one or contact us at{' '}
        <a href="mailto:support@griddyx.com">support@griddyx.com</a>.
      </Trans>
    ),
  },
  'Something went wrong while trying to log you in. Please try again later or call our Member Experience team if the problem persists.': {
    message: (
      <Trans>
        Something went wrong while trying to log you in. Please try again later
        or call our Member Experience team if the problem persists.
      </Trans>
    ),
  },
}

export const getError = (error, defaultInput) =>
  errors[error] || {
    field: defaultInput,
    message: error.charAt(0).toUpperCase() + error.slice(1),
  }

export default (setters, error, defaultInput) => {
  const finalError = getError(error.message, defaultInput)
  setters.setSubmitting(false)
  setters.setStatus({ [finalError.field || defaultInput]: finalError.message })
}

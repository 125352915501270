import React from 'react'
import PropTypes from 'prop-types'
import { withStateHandlers } from 'recompose'
import { Trans } from '@lingui/macro'
import { i18nMark } from '@lingui/react'
import * as mixpanel from 'utils/mixpanel'

import { Text } from 'components/text'
import {
  StyledNav,
  MeterDropdown,
  MeterDropdownItem,
  MeterList,
  Arrow,
  ToggleButton,
  NavList,
  ListItem,
  ListSpacer,
  Link,
  StyledLink,
  StyledItem,
} from './styled'
import MeterItem from '../Dropdown/MeterItem'
import Logout from '../../Sidebar/icons/Logout'
import urls from '../../urls'

export const MobileNav = ({
  isGriddyGuest,
  onClick,
  isOpen,
  members,
  logout,
  changeCurrentMember,
  isMeterListOpen,
  closeMeterList,
  openMeterList,
  bannerHeight,
}) => (
  <StyledNav isOpen={isOpen} paddingTop={bannerHeight ? bannerHeight : null}>
    {members.length > 1 && (
      <MeterDropdown>
        <MeterList>
          {members.map((member, index) => {
            const meterName = `Meter ${index + 1}`
            return (
              <MeterItem
                key={member.memberID}
                dataTestId={meterName}
                meterName={<Trans>Meter {index + 1}</Trans>}
                member={member}
                hide={!isMeterListOpen && !member.isSelected}
                onClick={() => {
                  if (member.isSelected) {
                    isMeterListOpen ? closeMeterList() : openMeterList()
                    return
                  }
                  closeMeterList()
                  onClick()
                  changeCurrentMember({
                    member,
                    nextMeterNum: index + 1,
                  })
                }}
              />
            )
          })}
        </MeterList>
        {isMeterListOpen ? (
          <MeterDropdownItem>
            <ListSpacer />
            <Link
              to="/account/meter-summary"
              onClick={() => {
                mixpanel.clickMeterSummary('dropdown')
                closeMeterList()
                onClick()
              }}
            >
              <Text large>{i18nMark('Meter Summary')}</Text>
            </Link>
          </MeterDropdownItem>
        ) : null}
        <ToggleButton
          isMeterListOpen={isMeterListOpen}
          onClick={isMeterListOpen ? closeMeterList : openMeterList}
        >
          <Arrow />
        </ToggleButton>
      </MeterDropdown>
    )}

    <NavList>
      {urls
        .filter(url => {
          return url.showLink
            ? url.showLink({ condition: members.length > 1 })
            : true
        })
        .map(
          ({
            guestLabel,
            label,
            to,
            exact,
            Icon,
            isActive,
            onItemClick,
            isPermitted,
          }) => {
            return isPermitted === false ? null : (
              <ListItem
                key={label}
                onClick={() => {
                  closeMeterList()
                  if (onItemClick) {
                    onItemClick()
                  }
                  onClick()
                }}
              >
                <StyledLink
                  to={to}
                  exact={exact}
                  isActive={isActive}
                  activeClassName="selected"
                >
                  <Icon />
                  <Text pl={15} large>
                    {isGriddyGuest && guestLabel ? (
                      <Trans id={guestLabel} />
                    ) : (
                      <Trans id={label} />
                    )}
                  </Text>
                </StyledLink>
              </ListItem>
            )
          }
        )}
      <ListItem
        onClick={() => {
          onClick()
          logout()
        }}
      >
        <StyledItem>
          <Logout />
          <Text pl={15} large>
            <Trans>Logout</Trans>
          </Text>
        </StyledItem>
      </ListItem>
    </NavList>
  </StyledNav>
)

MobileNav.propTypes = {
  isGriddyGuest: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  members: PropTypes.array.isRequired,
  logout: PropTypes.func.isRequired,
  changeCurrentMember: PropTypes.func.isRequired,
  isMeterListOpen: PropTypes.bool.isRequired,
  closeMeterList: PropTypes.func.isRequired,
  openMeterList: PropTypes.func.isRequired,
}

export default withStateHandlers(
  { isMeterListOpen: false },
  {
    openMeterList: () => () => ({ isMeterListOpen: true }),
    closeMeterList: () => () => ({ isMeterListOpen: false }),
  }
)(MobileNav)

import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from '@lingui/macro'

import passwordRules from 'pages/SignUp/SignUp/Form/passwordRules'

import Aux from 'components/Aux'

import Error from '../ErrorMessage'

import IconValid from './IconValid'
import IconInvalid from './IconInvalid'
import IconNeutral from './IconNeutral'

const validate = value => ({
  hasUppercase: passwordRules.hasUppercase.test(value),
  hasLowercase: passwordRules.hasLowercase.test(value),
  hasNumber: passwordRules.hasNumber.test(value),
  hasEightChars: passwordRules.hasEightChars.test(value),
})

const renderIcon = (isTouched, cond) => {
  if (cond) return <IconValid />
  if (!isTouched) return <IconNeutral />
  return <IconInvalid />
}

const ErrorPassword = ({ isTouched = false, password }) => {
  const { hasUppercase, hasLowercase, hasNumber, hasEightChars } = validate(
    password
  )
  return (
    <Aux>
      <Error untouched={!isTouched} valid={hasUppercase}>
        {renderIcon(isTouched, hasUppercase)}
        <Trans>Uppercase letter</Trans>
      </Error>
      <Error untouched={!isTouched} valid={hasLowercase}>
        {renderIcon(isTouched, hasLowercase)}
        <Trans>Lowercase letter</Trans>
      </Error>
      <Error untouched={!isTouched} valid={hasNumber}>
        {renderIcon(isTouched, hasNumber)}
        <Trans>Number</Trans>
      </Error>
      <Error untouched={!isTouched} valid={hasEightChars}>
        {renderIcon(isTouched, hasEightChars)}
        <Trans>8 or more characters</Trans>
      </Error>
    </Aux>
  )
}

ErrorPassword.propTypes = {
  password: PropTypes.string.isRequired,
  isTouched: PropTypes.bool,
}

export default ErrorPassword

import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from '@lingui/macro'

import { TextSemi, Text } from 'components/text'
import CloseButton from 'components/Modal/CloseButton'
import { Button, LinkButton } from 'components/buttons'

import Status from './Status'
import { WhiteBox, CloseButtonWrapper } from './styled'

const ModalBody = ({
  dataTestId,
  formStatus,
  setFormStatus,
  children,
  title,
  subTitle,
  alignLeft,
  onClose,
}) => {
  if (formStatus.updating) {
    return <Status updating />
  }
  if (formStatus.complete) {
    return <Status complete />
  }
  if (formStatus.error) {
    return (
      <WhiteBox width={['100%', '692px']} p={['20px 15px', '30px 80px']}>
        <TextSemi
          alignSelf={['baseline', 'center']}
          fontSize={['1.8rem', '3rem']}
        >
          <Trans>Oops!</Trans>
        </TextSemi>
        <Text alignSelf={['baseline', 'center']} mt="10px">
          <Trans>Something went wrong. Please try again.</Trans>
        </Text>
        <Button mt="15px" onClick={formStatus.error.onTryAgain}>
          <Trans>Try Again</Trans>
        </Button>
        <LinkButton mt="15px" onClick={() => setFormStatus({ form: true })}>
          Change Home Size
        </LinkButton>
      </WhiteBox>
    )
  }
  return (
    <WhiteBox
      data-testid={dataTestId}
      width={['100%', '692px']}
      p={['20px 15px', '30px 80px']}
    >
      {onClose && (
        <CloseButtonWrapper>
          <CloseButton onClick={onClose} />
        </CloseButtonWrapper>
      )}
      <TextSemi
        alignSelf={[alignLeft ? 'baseline' : 'center', 'center']}
        fontSize={['1.8rem', '3rem']}
      >
        {title}
      </TextSemi>
      <Text textAlign={[alignLeft ? 'left' : 'center', 'center']} mt="10px">
        {subTitle}
      </Text>
      {children}
    </WhiteBox>
  )
}

ModalBody.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  formStatus: PropTypes.object,
  setFormStatus: PropTypes.func,
  title: PropTypes.node.isRequired,
  subTitle: PropTypes.node.isRequired,
  children: PropTypes.node,
  alignLeft: PropTypes.bool,
  onClose: PropTypes.func,
}

export default ModalBody

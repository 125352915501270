import React from 'react'
import { SVG } from './styled'

const Valid = () => (
  <SVG
    width="10"
    height="10"
    viewBox="0 0 10 10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 5.923L3.857 9 9 1"
      strokeWidth="2"
      stroke="#8FAB36"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
)

export default Valid

import React from 'react'

import { SVG } from './styled'

const IconNeutral = () => (
  <SVG
    width="8"
    height="2"
    viewBox="0 0 8 2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3"
      y="6"
      width="8"
      height="2"
      rx="1"
      transform="translate(-3 -6)"
      fill="#9DB1C6"
      fillRule="evenodd"
    />
  </SVG>
)

export default IconNeutral

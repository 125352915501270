import '@babel/polyfill'
import 'whatwg-fetch'
import 'sanitize.css/sanitize.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { ConnectedRouter } from 'react-router-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { getPersistor } from '@rematch/persist'
import App from 'containers/App'
import I18nLoader from 'containers/I18nLoader'
import 'moment/locale/es'
import RedirectRoute from 'components/Routes/RedirectRoute'

import store, { history } from 'store'

import theme from './styles/theme'
import './styles/global'
import './styles/font-face-observer'

import * as serviceWorker from './serviceWorker'

import { companyInfo } from 'config/profile'

const persistor = getPersistor()
const MOUNT_NODE = document.getElementById('app')
const FAVICON = document.getElementById('favicon')

const render = () => {
  if (companyInfo.faviconLink !== '') {
    FAVICON.href = companyInfo.faviconLink
  }
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <I18nLoader>
          <ConnectedRouter history={history}>
            <ThemeProvider theme={theme}>
              <RedirectRoute component={App} />
            </ThemeProvider>
          </ConnectedRouter>
        </I18nLoader>
      </PersistGate>
    </Provider>,
    MOUNT_NODE
  )
}

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE)
    render()
  })
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'))
  })
    .then(() =>
      Promise.all([
        import('intl/locale-data/jsonp/en.js'),
        import('intl/locale-data/jsonp/es.js'),
      ])
    )
    .then(() => render())
    .catch(err => {
      throw err
    })
} else {
  render()
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

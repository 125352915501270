import styled from 'styled-components'

import { color as colors } from 'styles/variables'

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  padding: 0 10px;
`
export const WhiteBox = styled.div`
  background: #fff;
  border-radius: 4px;
  padding: 20px 30px;
  border: 1px solid #d5dee5;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  max-width: 530px;
`

export const CloseButton = styled.button`
  cursor: pointer;
  padding: 0;
  color: ${colors.secondary};

  &:hover {
    color: #000;
  }
`

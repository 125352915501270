import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Link } from 'react-router-dom'
import Media from 'react-media'
import styled from 'styled-components'
import { Trans } from '@lingui/macro'

import * as mixpanel from 'utils/mixpanel'

import { Button } from 'components/buttons'
import { Text } from 'components/text'
import mq, { breakpoints } from 'styles/mq'

const StyledLink = styled(Link)`
  display: block;
  color: #00c1d9;
  margin-top: 10px;

  ${mq.tablet`
    margin: 0;
    display: inline-block;
  `};
`

const Wrapper = styled.section`
  text-align: center;

  background: white;
  border: 1px solid #7d8d9e;
  border-radius: 4px;

  padding: 15px 0;
  margin-bottom: 10px;

  font-size: 1.6rem;
  line-height: 22px;

  ${mq.tablet`
    margin-bottom: 20px;
  `};
`

const StyledButton = styled(Button)`
  margin-top: 10px;
  margin-bottom: 5px;

  padding: 0 45px;
  line-height: 4.8rem;
  width: 215px;

  &:focus,
  &&:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: #fff;
  }
`

const FinishEnrollingButton = ({ match, location, children }) => (
  <StyledButton
    data-testid="finishEnrollingLink"
    to={{ pathname: '/enroll', state: { cancelTo: match.url } }}
    onClick={() => {
      mixpanel.clickFinishEnrolling(location.pathname)
    }}
  >
    {children}
  </StyledButton>
)

const FinishEnrollingLink = ({ match, location, children }) => (
  <StyledLink
    data-testid="finishEnrollingLink"
    to={{ pathname: '/enroll', state: { cancelTo: match.url } }}
    onClick={() => {
      mixpanel.clickFinishEnrolling(location.pathname)
    }}
  >
    {children}
  </StyledLink>
)

FinishEnrollingLink.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  children: PropTypes.node,
}

FinishEnrollingButton.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  children: PropTypes.node,
}

const HomeBanner = ({ match, location }) => (
  <Wrapper data-testid="ggWholesaleBanner">
    <Media query={{ minWidth: breakpoints.tablet }}>
      {isTabletOrAbove =>
        isTabletOrAbove ? (
          <Text>
            <Trans>
              Wholesale electricity prices change every five minutes, but stay
              below 3.0¢/kWh more than 86% of the time.
              <br />
              <FinishEnrollingLink match={match} location={location}>
                Click here
              </FinishEnrollingLink>{' '}
              when you're ready to join the Griddy movement.
            </Trans>
          </Text>
        ) : (
          <Text>
            <Trans>
              Electricity prices change every 5 minutes,
              <br /> but stay below 3.0¢/kWh 86% of the time.
              <br />
              <FinishEnrollingButton match={match} location={location}>
                Finish Enrolling
              </FinishEnrollingButton>
            </Trans>
          </Text>
        )
      }
    </Media>
  </Wrapper>
)
HomeBanner.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

const UsageBanner = ({ match, location }) => (
  <Wrapper data-testid="ggUsageBanner">
    <Media query={{ minWidth: breakpoints.tablet }}>
      {isTabletOrAbove =>
        isTabletOrAbove ? (
          <Text>
            <Trans>
              Get visibility into your electricity usage, habits, and costs with
              our helpful tools.
              <br />
              <FinishEnrollingLink match={match} location={location}>
                Click here
              </FinishEnrollingLink>{' '}
              to take control of your usage.
            </Trans>
          </Text>
        ) : (
          <Text>
            <Trans>
              Get visibility into your electricity usage, habits, and costs with
              our helpful tools.
              <FinishEnrollingButton match={match} location={location}>
                Finish Enrolling
              </FinishEnrollingButton>
            </Trans>
          </Text>
        )
      }
    </Media>
  </Wrapper>
)
UsageBanner.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

const StatementBanner = ({ match, location }) => (
  <Wrapper data-testid="ggStatementBanner">
    <Media query={{ minWidth: breakpoints.tablet }}>
      {isTabletOrAbove =>
        isTabletOrAbove ? (
          <Text>
            <Trans>
              Avoid bill shock. Griddy's pay-as-you-go billing means you know
              exactly what you're paying.
              <br />
              When you're ready for total transparency,{' '}
              <FinishEnrollingLink match={match} location={location}>
                click here
              </FinishEnrollingLink>
              .
            </Trans>
          </Text>
        ) : (
          <Text>
            <Trans>
              Avoid bill shock with Griddy’s
              <br />
              pay-as-you-go billing.
              <br />
              <FinishEnrollingButton match={match} location={location}>
                Finish Enrolling
              </FinishEnrollingButton>
            </Trans>
          </Text>
        )
      }
    </Media>
  </Wrapper>
)
StatementBanner.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

const SavingsBanner = ({ match, location }) => (
  <Wrapper data-testid="ggSavingsBanner">
    <Media query={{ minWidth: breakpoints.tablet }}>
      {isTabletOrAbove =>
        isTabletOrAbove ? (
          <Text>
            <Trans>
              Our members saved an average of $358 last year.
              <br />
              Let those savings start rolling in by{' '}
              <FinishEnrollingLink match={match} location={location}>
                joining Griddy
              </FinishEnrollingLink>
              .
            </Trans>
          </Text>
        ) : (
          <Text p="0 10px">
            <Trans>
              Our members saved an average of $358 dollars last year just by
              going Griddy.
              <FinishEnrollingButton match={match} location={location}>
                Finish Enrolling
              </FinishEnrollingButton>
            </Trans>
          </Text>
        )
      }
    </Media>
  </Wrapper>
)
SavingsBanner.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

const AccountBanner = ({ match, location }) => (
  <Wrapper data-testid="ggAccountBanner">
    <Media query={{ minWidth: breakpoints.tablet }}>
      {isTabletOrAbove =>
        isTabletOrAbove ? (
          <Text>
            <Trans>
              No credit checks. No early termination fees. No contracts.
              <br />
              <FinishEnrollingLink match={match} location={location}>
                Click here
              </FinishEnrollingLink>{' '}
              to finally be treated fairly by your electric provider.
            </Trans>
          </Text>
        ) : (
          <Text>
            <Trans>
              No credit checks. No early termination fees.
              <br />
              No contracts. Isn’t it nice to be treated fairly?
              <br />
              <FinishEnrollingButton match={match} location={location}>
                Finish Enrolling
              </FinishEnrollingButton>
            </Trans>
          </Text>
        )
      }
    </Media>
  </Wrapper>
)
AccountBanner.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

const Banner = () => (
  <Switch>
    <Route exact path="/" component={HomeBanner} />
    <Route exact path="/usage" component={UsageBanner} />
    <Route exact path="/statement" component={StatementBanner} />
    <Route exact path="/savings" component={SavingsBanner} />
    <Route exact path="/account" component={AccountBanner} />
  </Switch>
)

export default Banner

import React from 'react'

const Usage = () => (
  <svg width="26" height="26" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h26v26H0z" />
      <path
        d="M3.25 13h4.333c.599 0 1.084.534 1.084 1.192v9.533c0 .658-.485 1.192-1.084 1.192H3.25c-.598 0-1.083-.534-1.083-1.192v-9.533c0-.658.485-1.192 1.083-1.192zm1.083 2.167v7.583H6.5v-7.583H4.333zm6.5-14.084h4.334c.598 0 1.083.534 1.083 1.192v21.45c0 .658-.485 1.192-1.083 1.192h-4.334c-.598 0-1.083-.534-1.083-1.192V2.275c0-.658.485-1.192 1.083-1.192zm1.084 2.167v19.5h2.166V3.25h-2.166zm6.5 5.417h4.333c.598 0 1.083.52 1.083 1.16v13.929c0 .641-.485 1.16-1.083 1.16h-4.333c-.599 0-1.084-.519-1.084-1.16V9.827c0-.64.485-1.16 1.084-1.16zm1.083 2.166V22.75h2.167V10.833H19.5z"
        fill="currentColor"
      />
    </g>
  </svg>
)

export default Usage

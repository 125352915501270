import * as C from './constants'

export const setPriceLockRequest = payload => ({
  type: C.SET_PRICE_LOCK_REQUEST,
  payload,
})

export const setPriceLockRequestSuccess = payload => ({
  type: C.SET_PRICE_LOCK_REQUEST_SUCCESS,
  payload,
})

export const setPriceLockRequestFailure = payload => ({
  type: C.SET_PRICE_LOCK_REQUEST_FAILURE,
  payload,
})

export const getPriceLockRate = payload => ({
  type: C.GET_PRICE_LOCK_RATE,
  payload,
})

export const getPriceLockRateSuccess = payload => ({
  type: C.GET_PRICE_LOCK_RATE_SUCCESS,
  payload,
})

export const getPriceLockRateFailure = payload => ({
  type: C.GET_PRICE_LOCK_RATE_FAILURE,
  payload,
})

export const updateAutoSummerLock = payload => ({
  type: C.UPDATE_AUTO_SUMMER_LOCK,
  payload,
})

export const updateAutoSummerLockSuccess = payload => ({
  type: C.UPDATE_AUTO_SUMMER_LOCK_SUCCESS,
  payload,
})

export const updateAutoSummerLockFailure = payload => ({
  type: C.UPDATE_AUTO_SUMMER_LOCK_FAILURE,
  payload,
})

export const setIDs = payload => ({
  type: C.SET_IDS,
  payload,
})

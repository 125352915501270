import React from 'react'
import PropTypes from 'prop-types'

const SmallHome = ({ isChecked }) =>
  isChecked ? (
    <svg width="103" height="62" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <g transform="translate(0 58)" fill="#7D8D9E">
          <rect
            transform="matrix(-1 0 0 1 66 0)"
            width="66"
            height="1"
            rx=".5"
          />
          <rect
            transform="matrix(-1 0 0 1 160 0)"
            x="68"
            width="24"
            height="1"
            rx=".5"
          />
          <rect
            transform="matrix(-1 0 0 1 196 0)"
            x="93"
            width="10"
            height="1"
            rx=".5"
          />
          <rect x="20" y="3" width="29" height="1" rx=".5" />
          <rect x="50" y="3" width="47" height="1" rx=".5" />
          <rect x="5" y="3" width="4" height="1" rx=".5" />
          <rect x="10" y="3" width="8" height="1" rx=".5" />
        </g>
        <rect
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          x="67.5"
          y="9.5"
          width="8"
          height="15"
          rx="1"
        />
        <path
          d="M22.5 33.275V58.5h59V33.275L52 14.592 22.5 33.275z"
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
        />
        <circle
          stroke="#7D8D9E"
          fillOpacity=".5"
          fill="#ED0874"
          fillRule="nonzero"
          cx="52"
          cy="26"
          r="5"
        />
        <path fill="#7D8D9E" fillRule="nonzero" d="M47 24h10v1H47z" />
        <rect
          stroke="#7D8D9E"
          fillOpacity=".5"
          fill="#ED0874"
          fillRule="nonzero"
          x="65.5"
          y="35.5"
          width="9"
          height="14"
          rx="1"
        />
        <rect
          fill="#7D8D9E"
          fillRule="nonzero"
          x="28"
          y="49"
          width="12"
          height="1"
          rx=".5"
        />
        <rect
          fill="#7D8D9E"
          fillRule="nonzero"
          x="64"
          y="49"
          width="12"
          height="1"
          rx=".5"
        />
        <rect
          stroke="#7D8D9E"
          fillOpacity=".5"
          fill="#ED0874"
          fillRule="nonzero"
          x="29.5"
          y="35.5"
          width="9"
          height="14"
          rx="1"
        />
        <path fill="#7D8D9E" fillRule="nonzero" d="M29 42h10v1H29z" />
        <path
          d="M18.728 30.124a.5.5 0 0 0-.228.42v4.618a.5.5 0 0 0 .772.42l31.913-20.65a1.5 1.5 0 0 1 1.63 0l31.913 20.65a.5.5 0 0 0 .772-.42v-4.618a.5.5 0 0 0-.228-.42l-33-21.353a.5.5 0 0 0-.544 0l-33 21.353z"
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path fill="#7D8D9E" fillRule="nonzero" d="M65 42h10v1H65z" />
        <rect
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          x="45.5"
          y="35.5"
          width="13"
          height="23"
          rx="1"
        />
        <circle fill="#7D8D9E" fillRule="nonzero" cx="48" cy="47" r="1" />
      </g>
    </svg>
  ) : (
    <svg width="103" height="62" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <g transform="translate(0 58)" fill="#7D8D9E">
          <rect
            transform="matrix(-1 0 0 1 66 0)"
            width="66"
            height="1"
            rx=".5"
          />
          <rect
            transform="matrix(-1 0 0 1 160 0)"
            x="68"
            width="24"
            height="1"
            rx=".5"
          />
          <rect
            transform="matrix(-1 0 0 1 196 0)"
            x="93"
            width="10"
            height="1"
            rx=".5"
          />
          <rect x="20" y="3" width="29" height="1" rx=".5" />
          <rect x="50" y="3" width="47" height="1" rx=".5" />
          <rect x="5" y="3" width="4" height="1" rx=".5" />
          <rect x="10" y="3" width="8" height="1" rx=".5" />
        </g>
        <rect
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          x="67.5"
          y="9.5"
          width="8"
          height="15"
          rx="1"
        />
        <path
          d="M22.5 33.275V58.5h59V33.275L52 14.592 22.5 33.275z"
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
        />
        <circle
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          cx="52"
          cy="26"
          r="5"
        />
        <path fill="#7D8D9E" fillRule="nonzero" d="M47 24h10v1H47z" />
        <rect
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          x="65.5"
          y="35.5"
          width="9"
          height="14"
          rx="1"
        />
        <rect
          fill="#7D8D9E"
          fillRule="nonzero"
          x="28"
          y="49"
          width="12"
          height="1"
          rx=".5"
        />
        <rect
          fill="#7D8D9E"
          fillRule="nonzero"
          x="64"
          y="49"
          width="12"
          height="1"
          rx=".5"
        />
        <rect
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          x="29.5"
          y="35.5"
          width="9"
          height="14"
          rx="1"
        />
        <path fill="#7D8D9E" fillRule="nonzero" d="M29 42h10v1H29z" />
        <path
          d="M18.728 30.124a.5.5 0 0 0-.228.42v4.618a.5.5 0 0 0 .772.42l31.913-20.65a1.5 1.5 0 0 1 1.63 0l31.913 20.65a.5.5 0 0 0 .772-.42v-4.618a.5.5 0 0 0-.228-.42l-33-21.353a.5.5 0 0 0-.544 0l-33 21.353z"
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path fill="#7D8D9E" fillRule="nonzero" d="M65 42h10v1H65z" />
        <rect
          stroke="#7D8D9E"
          fill="#FFF"
          fillRule="nonzero"
          x="45.5"
          y="35.5"
          width="13"
          height="23"
          rx="1"
        />
        <circle fill="#7D8D9E" fillRule="nonzero" cx="48" cy="47" r="1" />
      </g>
    </svg>
  )

SmallHome.propTypes = {
  isChecked: PropTypes.bool,
}

export default SmallHome

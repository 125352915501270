import { Component } from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'

const modalContainerCSS = `
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(242, 243, 246, 0.6);
  z-index: 555;
  padding: 0 15px;
`

class BlurModal extends Component {
  constructor(props) {
    super(props)
    const modalContainer = document.createElement('div')
    modalContainer.id = 'blur-modal-container'
    modalContainer.setAttribute('data-testid', 'blurModal')
    modalContainer.style.cssText = modalContainerCSS
    this.modalContainer = modalContainer
  }

  componentDidMount() {
    this.appendModalContainer()
    document.addEventListener('click', this.handleOutsideClick)
    this.modalContainer.addEventListener('touchmove', this.preventTouchScroll)
  }

  componentWillUpdate(nextProps) {
    if (this.props.parentElementId !== nextProps.parentElementId) {
      this.changeModalContainerPosition(
        this.props.parentElementId,
        nextProps.parentElementId
      )
    }
  }

  componentWillUnmount() {
    this.removeModalContainer()
    document.removeEventListener('click', this.handleOutsideClick)
    this.modalContainer.removeEventListener(
      'touchmove',
      this.preventTouchScroll
    )
  }

  preventTouchScroll = e => {
    if (e.target === this.modalContainer) {
      e.preventDefault()
    }
  }

  appendModalContainer = () => {
    const { parentElementId } = this.props
    const parentElement = document.getElementById(parentElementId)
    parentElement.classList.add('blur-children')
    parentElement.appendChild(this.modalContainer)
  }

  changeModalContainerPosition = (prevParentElementId, parentElementId) => {
    const prevParentElement = document.getElementById(prevParentElementId)
    const parentElement = document.getElementById(parentElementId)
    prevParentElement.classList.remove('blur-children')
    parentElement.classList.add('blur-children')
    parentElement.appendChild(this.modalContainer)
  }

  removeModalContainer = () => {
    const { parentElementId } = this.props
    const parentElement = document.getElementById(parentElementId)
    parentElement.classList.remove('blur-children')
    parentElement.removeChild(this.modalContainer)
  }

  handleOutsideClick = ({ target }) => {
    target === this.modalContainer &&
      this.props.onOutsideClick &&
      this.props.onOutsideClick()
  }

  render() {
    return createPortal(this.props.children, this.modalContainer)
  }
}

BlurModal.propTypes = {
  parentElementId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onOutsideClick: PropTypes.func,
}

export default BlurModal

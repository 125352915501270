import { i18nMark } from '@lingui/react'
import {
  Usage,
  Statement,
  Account,
  Support,
  Wholesale,
  Renewal,
} from './Sidebar/icons'
import { routes } from 'config/profile'

const urls = [
  {
    label: i18nMark('Wholesale'),
    to: '/wholesale',
    Icon: Wholesale,
    isPermitted: routes.wholesale,
  },
  {
    label: i18nMark('Account'),
    guestLabel: i18nMark('Guest Account'),
    to: '/account',
    Icon: Account,
    isActive: (match, location) => {
      if (
        location.pathname.startsWith('/account') &&
        location.pathname !== '/account/meter-summary'
      ) {
        return true
      }
      return false
    },
  },
  {
    label: i18nMark('Renewal'),
    to: '/renewal',
    Icon: Renewal,
    isPermitted: false,
  },
  {
    label: i18nMark('Electricity Usage'),
    to: '/usage',
    Icon: Usage,
  },
  {
    label: i18nMark('Statement'),
    to: '/statement',
    Icon: Statement,
  },
  {
    label: i18nMark('Support'),
    to: '/help',
    Icon: Support,
  },
]

export default urls

import styled, { css } from 'styled-components'
import { space } from 'styled-system'
import { color, input, font } from 'styles/variables'
import mq from 'styles/mq'
import CheckIcon from 'assets/images/icons/check.svg'
import comingSoon from 'assets/images/price_lock_coming_soon.svg'
import lockedIcon from 'assets/images/icons/locked.svg'
import unlockedIcon from 'assets/images/icons/unlocked.svg'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding-bottom: 20px;
`

export const LockContainer = styled.div`
  display: none;
  flex-direction: column;
  background: #fff;
  padding: 0 17px;
  align-items: center;
`
export const PlanBannerContainer = styled.div`
  display: none;
  flex-direction: column;
  justify-content: center;
  padding: 15px 15px;
  align-items: center;

  ${mq.tablet`
    position: relative;
    width: 100%;
    height: auto;
    `};

  background: ${color.neutral5};
  border-radius: 10px;
`

export const LockButton = styled.div`
  height: 50px;
  width: auto;
  min-height: 25px;
  min-width: 25px;
  font-weight: ${font.semiBold};
  background-color: ${props => {
    if (props.locked && props.eligibleToUnlock) {
      return color.green
    }
    return props.locked ? color.secondary : color.primary
  }};
  border-radius: 30px;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  color: #fff;
  text-align: center;
  padding: 15px;
  cursor: pointer;
  box-shadow: 1px 1px 25px 1px ${color.neutral3};
`

export const Button = styled.div`
  cursor: pointer;
  outline: none;
  line-height: 2.6;
  background: ${color.primary};
  border-radius: 30px;
  text-decoration: none;
  color: #fff;
  font-family: ${font.customFont};
  font-weight: ${font.semiBold};
  font-size: 1.8rem;
  transition: all 0.3s ease;
  border: 1px solid transparent;
  text-align: center;
  height: 50px;
  display: inline-block;
  padding: 0 12px;

  ${props =>
    props.secondary &&
    css`
      background: #7d8d9e;
      font-size: 1.4rem;
      line-height: 32px;
      height: 34px;
      width: auto;
      margin-left: 10px;
      &:hover {
        background: #9db1c6;
      }
    `};

  ${props =>
    props.main &&
    css`
      background: ${color.primary};
      font-size: 1.4rem;
      line-height: 32px;
      height: 34px;
      width: auto;
      &:hover {
        background: #9db1c6;
      }
      box-shadow: 1px 1px 25px 1px ${color.neutral4};
    `};
`

export const Divider = styled.div`
  border: none;
  border-top: 1px solid ${color.neutral4};
  width: 100%;
  margin-top: 30px;
  display: none;
`

export const AutoSummerLockContainer = styled.div`
  margin-top: 8px;
  padding-bottom: 200px;
  padding-left: 17px;
  padding-right: 17px;
`

export const StyledCheckbox = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
  margin-right: 20px;
  border-radius: 2px;
  border: 1px solid ${input.checkbox};
  transition: 0.2s background-color;
  background: url(${CheckIcon}) no-repeat center center;
  background-color: ${props => (props.isChecked ? input.checkbox : '#fff')};
  ${space};
`

export const PriceLockComingSoon = styled.div`
  width: auto;
  height: 300px;
  background: url(${comingSoon}) no-repeat center center;
`

export const LockedIcon = styled.div`
  width: 60px;
  height: 40px;
  background: url(${lockedIcon}) no-repeat center center;
`

export const UnlockedIcon = styled.div`
  width: 60px;
  height: 40px;
  background: url(${unlockedIcon}) no-repeat center center;
`

export const CheckboxContainer = styled.div`
  background-color: ${color.neutral5};
  padding: 10px;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
`

export const ErrorContainer = styled.div`
  color: white;
  background-color: ${color.error};
  width: 100%;
  display: flex;
  justify-content: center;
`

export const A = styled.a`
  color: ${color.primary} !important;
`
export const LearnMoreButton = styled.div`
  cursor: pointer;
  outline: none;
  line-height: 2.6;
  background: ${color.primary};
  border-radius: 30px;
  text-decoration: none;
  color: ${color.secondary};
  font-family: ${font.customFont};
  font-weight: ${font.semiBold};
  font-size: 14px;
  transition: all 0.3s ease;
  border: 1px solid transparent;
  text-align: center;
  height: 36px;
  display: inline-block;
  padding: 0 16px;
  &:hover {
    background: #9db1c6;
  }
`

function getCoords(element) {
  const box = element.getBoundingClientRect()

  const body = document.body
  const docEl = document.documentElement

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

  const clientTop = docEl.clientTop || body.clientTop || 0
  const clientLeft = docEl.clientLeft || body.clientLeft || 0

  const top = box.top + scrollTop - clientTop
  const left = box.left + scrollLeft - clientLeft

  return { top: Math.round(top), left: Math.round(left) }
}

export function getScrollTop() {
  const pageYOffset = window.pageYOffset
  return (
    pageYOffset || document.body.scrollTop || document.documentElement.scrollTop
  )
}

export function scrollToElement({
  target,
  duration = 200,
  isCancellable = true,
}) {
  const targetPosition = getCoords(target).top
  duration = Math.round(duration)
  if (duration < 0) {
    return Promise.reject(new Error(`Duration can't be a negative number`))
  }

  if (duration === 0) {
    document.body.scrollTop = targetPosition
    return Promise.resolve()
  }

  const startTime = Date.now()
  const endTime = startTime + duration

  const startTop = getScrollTop()
  const distance = targetPosition - startTop

  // based on http://en.wikipedia.org/wiki/Smoothstep
  const smoothStep = (start, end, point) => {
    if (point <= start) {
      return 0
    }
    if (point >= end) {
      return 1
    }
    const x = (point - start) / (end - start)

    return x * x * (3 - 2 * x)
  }

  return new Promise((resolve, reject) => {
    // This is to keep track of where the element's scrollTop is
    // supposed to be, based on what we're doing
    let prevTop = getScrollTop()

    // This is like a think function from a game loop
    const scrollFrame = () => {
      if (isCancellable && getScrollTop() !== prevTop) {
        reject(new Error('Scroll interrupted'))
        return
      }

      // set the scrollTop for this frame
      const now = Date.now()
      const point = smoothStep(startTime, endTime, now)
      const frameTop = Math.round(startTop + distance * point)

      document.body.scrollTop = frameTop

      // check if we're done!
      if (now >= endTime) {
        resolve()
        return
      }

      // If we were supposed to scroll but didn't, then we
      // probably hit the limit, so consider it done; not
      // interrupted.
      if (getScrollTop() === prevTop && getScrollTop() !== frameTop) {
        resolve()
        return
      }
      prevTop = getScrollTop()

      // schedule next frame for execution
      requestAnimationFrame(scrollFrame)
    }

    // boostrap the animation process
    requestAnimationFrame(scrollFrame)
  })
}

import { pathOr } from 'ramda'
import { createSelector } from 'reselect'

const selectRoute = state => state.route

const selectAppState = state => state.app

export const selectHasNoMemberOrMeter = createSelector(
  selectAppState,
  substate => substate.hasNoMemberOrMeter
)

export const selectLocation = createSelector(
  selectRoute,
  routeState => routeState.location
)

export const selectLocationPath = createSelector(
  selectLocation,
  selectLocation => pathOr('', ['pathname'], selectLocation)
)

export const selectIsWebView = createSelector(selectAppState, substate =>
  Boolean(substate.webView)
)

export const selectWebView = createSelector(
  selectAppState,
  substate => substate.webView
)

export const selectSwitchMeter = createSelector(
  selectAppState,
  substate => substate.switchMeter
)

export const selectInAppMessages = createSelector(
  selectAppState,
  substate => substate.inAppMessages
)

export const selectMessages = createSelector(
  selectInAppMessages,
  selectedMessages => {
    return selectedMessages
      .filter(m => {
        const timeStamp = pathOr(
          false,
          ['customPayload', 'newsCardCreatedTimestamp'],
          m
        )
        if (!timeStamp) {
          return false
        }
        return true
      })
      .map(m => {
        const body = pathOr(false, ['customPayload', 'newsCardBody'], m)
        if (body) {
          m.customPayload.newsCardTruncatedBody = `${body
            .substring(0, 76)
            .trim()}...`
          m.customPayload.newsCardDisplayDate = new Date(
            m.createdAt
          ).toISOString()
        }
        return m
      })
  }
)

export const selectNewMessage = createSelector(
  selectMessages,
  messages => messages[0]
)

export const selectShowMessagesInbox = createSelector(
  selectAppState,
  substate => substate.showMessagesInbox
)

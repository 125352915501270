import React from 'react'

const Statement = () => (
  <svg width="26" height="26" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h26v26H0z" />
      <path
        d="M19.5 10.836h-6.528a1.083 1.083 0 0 1-1.084-1.084V3.258H6.5v19.5h13V10.836zM12.847 1.09a1.06 1.06 0 0 1 .89.312l7.584 7.583c.238.238.334.534.318.82h.028V23.3c0 .897-.728 1.625-1.625 1.625H5.958A1.625 1.625 0 0 1 4.333 23.3V2.716c0-.897.728-1.625 1.625-1.625h6.89zM9.182 13h7.583a.542.542 0 1 1 0 1.083H9.182a.542.542 0 1 1 0-1.083zm0 3.25h7.583a.542.542 0 1 1 0 1.083H9.182a.542.542 0 1 1 0-1.083zm0 3.25h7.583a.542.542 0 1 1 0 1.083H9.182a.542.542 0 1 1 0-1.083zm4.873-14.716V8.67h3.885l-3.885-3.885z"
        fill="currentColor"
      />
    </g>
  </svg>
)

export default Statement

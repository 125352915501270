import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Trans } from '@lingui/macro'

import { input } from 'styles/variables'
import CheckIcon from 'assets/images/icons/checkmark_green.svg'
import { TextSemi } from 'components/text'
import Loader from 'components/Loader'

import { WhiteBox } from '../styled'

const StyledCheckbox = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid ${input.borderColor};
  background: url(${CheckIcon}) no-repeat center center;
`

const Status = ({ updating, complete }) => (
  <WhiteBox width="303px" p="20px 30px">
    {updating && (
      <>
        <TextSemi mb={20}>
          <Trans>Customizing Your Experience</Trans>
        </TextSemi>
        <Loader color="#ed0874" size={16} />
      </>
    )}
    {complete && (
      <>
        <TextSemi mb={10}>
          <Trans>Complete</Trans>
        </TextSemi>
        <StyledCheckbox />
      </>
    )}
  </WhiteBox>
)

Status.propTypes = {
  updating: PropTypes.bool,
  complete: PropTypes.bool,
}

export default Status

import styled from 'styled-components'
import { space } from 'styled-system'
import { input, color } from 'styles/variables'

const RadioButton = styled.div`
  position: relative;
  width: 25px;
  height: 25px;
  flex: 0 0 25px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid ${input.borderColorFocused};
  margin-right: 10px;

  &::after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: ${props => (props.isChecked ? color.primary : '#fff')};
  }
  ${space};
`

export default RadioButton
